import React from 'react'
import ReactiveUserForm from '../../Components/shared/Reactvie Users/ReactiveUserForm'
import { Container,H1 } from '../../Components/shared/Reactvie Users/ReactiveUserPageElements'
const AdminReactiveUsers = () => {
  return (
      <Container>
          <H1>Re-Active Users</H1>
        <ReactiveUserForm url={'/api/v1/admin-users/reActiveUser'} />
      </Container>
  )
}

export default AdminReactiveUsers