import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import BackwordButton from '../../Button/BackButton/BackButton';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AiOutlineCloseCircle,AiOutlineMinus } from "react-icons/ai";
import ClipLoader from 'react-spinners/ClipLoader';
import { SUCCESS, ERROR } from './../../alert/Alert_Types';
import axios from 'axios';
import useAlert from '../../../Hooks/useAlert';

const AdminViewUsersTable = (props) => {

    const { setAlert } = useAlert();

    const [pending, setPending] = useState(false);

    const onRelease = async(id,role) => {
        setPending(true);

        let url = '';

        if (role === 'sub_warden') {
            url=`/api/v1/admin-users/releaseSubWarden/${id}`
        }
        if (role === 'academic_warden') {
            url=`/api/v1/admin-users/releaseAcademicWarden/${id}`
            
        }

        try {
            
            const res = await axios.patch(url);
            // console.log(res);
            setPending(false);
            if (res.status === 201) {
            setAlert(`${res.data.message || 'Success'}`,SUCCESS,5000)
                
            }
        } catch (error) {
            // console.log(error.response?.data);
            // console.log(error.response?.data?.message);
            setPending(false);
            setAlert(`${error.response?.data?.message}`, ERROR, 5000);

        }
    }

    let rows = [];
    
    // console.log({ users: props.data.data.users });
    const users = props?.data?.data?.users

    users.forEach(user => {
        const h = {
            id: user.id,
            name: user.name,
            role: user.role,
            email: user.email,
            
        }

        rows.push(h);
    })

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="left">Role</StyledTableCell>
                            <StyledTableCell align="center">Actions</StyledTableCell>
                            <StyledTableCell align="right">Email</StyledTableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row) => (
                            <TableRow hover={true}
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row.role}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.role === 'sub_warden' || row.role === 'academic_warden'
                                        ? <> <IconButton onClick={()=>{onRelease(row.id,row.role)}} aria-label="delete">
                                            {pending && <ClipLoader size={20}/>}
                                            <AiOutlineCloseCircle color='#ed343a' />
                                        </IconButton>
                                            <Typography variant="caption" display="block" gutterBottom>
                                                Release from Hostel
                                            </Typography></>
                                        : <IconButton disabled aria-label="delete">
                                            <AiOutlineMinus />
                                        </IconButton>
                                    }
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.email}</StyledTableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <BackwordButton nav1={props.nav2} />
        </>
    )
};

export default AdminViewUsersTable