import React, { useContext } from 'react'
import { AlertContainer, AlertText } from './AlertElements';
import AlertContext from '../../context/alert/alertContext';

// import { ERROR, SUCCESS, WARNING } from './Alert_Types';

const Alert = () => {

  const alertContext = useContext(AlertContext);
  const { alerts } = alertContext;


  return (

    alerts.length > 0 && alerts.map(alert => {
      return (
        <AlertContainer key={alert.id} color={alert.type}>
          <AlertText> {alert.msg} </AlertText>
        </AlertContainer>
      )
    })
  )
}

export default Alert