import React from "react";
import ViewStudents from "../../Components/shared/ViewStudents/ViewStudents";
import { Container } from "@mui/system";
import {SpinnerContainer} from "../../Components/shared/ViewStudents/ViewStudentsElements";
import ClipLoader from "react-spinners/ClipLoader";
import useFetch from '../../Hooks/useFetch';

const AcademicWardenStudents = (props) => {
  const { data, isPending, isError } = useFetch(
    "/api/v1/students/getMyStudents"
  );
  const dataArr = data?.data.students;
  // console.log(dataArr);
  return (
    <>
      <Container>
        {isError && <h1>Something went wrong ... 😥</h1>}

        {isPending && (
          <SpinnerContainer>
            <ClipLoader size={50} />
          </SpinnerContainer>
        )}

        {data && <ViewStudents data={dataArr} nav2={props.nav3} />}
      </Container>
    </>
  );
};

export default AcademicWardenStudents;
