import React from 'react'
import AddStudent from '../../Components/SubWardnes/Add Student/addStudent'

const SubWardenAddStudents = (props) => {
  return (
      <>
          {/* <h1>this is # subwarden add students # page </h1>
          <p>this is for subwarden to add students to his/her hostel</p> */}
          <AddStudent nav2={props.nav3}/>
    </>
  )
}

export default SubWardenAddStudents