import { useReducer } from "react"
import authReducer from './authReducer';
import AuthContext from './authContext';
import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
    SET_LOADING
} from './../types'
import axios from "axios";
const AuthState = props => {
    const initialState = {
        isAuthenticated: null,
        loading: false,
        user: null,
        error:null
    }

    const [state, dispatch] = useReducer(authReducer, initialState);

    // user logout
    const logout = async () => {
        dispatch({ type: SET_LOADING });
        try {
            const res = await axios.get('/api/v1/auth/logout');
            dispatch({ type: LOGOUT })
            // console.log({'logout res':res});
        } catch (err) {
            // console.log(err.response.data);
            dispatch({ type: LOGOUT })
            
        }

    }

    // load user
    const loadUser = async () => {
        dispatch({ type: SET_LOADING });
        try {
            const res = await axios.get('/api/v1/auth/getLoggedInUser');
            dispatch({ type: USER_LOADED, payload: res.data.data.loggedInUser });
        } catch (err) {
            // console.log(err.response.data);
            dispatch({ type: AUTH_ERROR })
        }
    }

     // user login
    // need to set loading true
    const login = async (formData) => {
        
        dispatch({ type: SET_LOADING });
        try {
            const res = await axios.post('/api/v1/auth/login',formData);
            dispatch({
                type: LOGIN_SUCCESS,
                payload:res.data
            })
            loadUser();
        } catch (error) {
            // console.log(error.response.data);
            dispatch({
                type: LOGIN_FAIL,
                payload:error.response.data.message
                
            })
        }
        
    }

    // clear errors
    const clearErrors = () => {
        dispatch({ type: CLEAR_ERRORS });
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: state.isAuthenticated,
                loading: state.loading,
                user: state.user,
                error: state.error,
                login,
                logout,
                loadUser,
                clearErrors
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )

}

export default AuthState;