import React from 'react'
import { useParams } from 'react-router-dom';
import ViewQuery from '../../Components/shared/ViewQuery/ViewQuery'
import { Container, Header, Top,SpinnerContainer } from '../../Components/shared/ViewQuery/ViewQueryPageElements'
import useFetch from './../../Hooks/useFetch';
import ClipLoader from 'react-spinners/ClipLoader'

const SOViewQuery = (props) => {

    const { id } = useParams();

    const { data, isPending, isError } = useFetch(`/api/v1/queries/${id}`);
    
    const query = data?.data?.query;

  return (
    <Container>
      <Top>
        <Header>View Query</Header>
      </Top>
      {isPending && <SpinnerContainer> <ClipLoader />  </SpinnerContainer>}
      {isError && <h2>Something went wrong .... 😥 </h2>}
      {data && <ViewQuery data={query} nav2={props.nav3} />}
            
      {/* <ViewQuery/> */}
    </Container>
  )
}

export default SOViewQuery