import { TextField } from "@mui/material";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth.js"
import { AiOutlineCloseCircle, AiOutlineSend } from "react-icons/ai";
import axios from 'axios';
import useAlert from '../../Hooks/useAlert';
import { ERROR, SUCCESS } from '../alert/Alert_Types';
import { ClipLoader } from 'react-spinners';
import {
    Container,
    TextBox,
    Top,
    Header,
    Row,
    Buttons,
    Form
}from './CreateQueryElements'
import BackwordButton from "../Button/BackButton/BackButton.js";

const queryTypes = [
    {
        value: 'regular',
        label: 'Regular'
    },
    {
        value: 'maintenance',
        label: 'Maintenance'
    }
];

const CreateQuery = (props) => {
    const {user}=useAuth();
    const [pending, setPending] = useState(false);
    const { setAlert} = useAlert();
    const [query, setQuery] = useState('');
    const [queryType, setQueryType] = useState('');

    const message = (query + "\n\nName: " + user.initials+ " " + user.lastName + "\n Student No: " + user.studentNo + "\n Hostel: "+ user.hostel.name + "\n Faculty: " + user.faculty + "\n Level: " + user.level +"\n RoomNo: "+user.roomNo );
    const reset = e =>{
        setQuery('');
    }

    const onSubmit = async e =>{
        e.preventDefault();
        const formData = {message,queryType}
        if (user.initials && user.lastName && user.studentNo && user.hostel.name && user.faculty && user.level) {        
            setPending(true);
            try {
                // console.log(formData);
                const res = await axios.post('/api/v1/queries', formData);
                // console.log(formData);
                // console.log({res});
                
                if (res.status === 201) {
                    setPending(false);
                    setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
                }
        
            } catch (error) {
                // console.log(error);
                // console.log(error.response.data.message);
                setAlert(`${error?.response.data.message} 🙄🤨`, ERROR, 5000);
                setPending(false);
            }
        }else{
            setAlert(`PLease Update Your Account `, ERROR, 5000);
        }
    }

    return (
        <Container>
            <Top>
                <Header>Create Query</Header>
            </Top>
            <Form onSubmit={onSubmit}>
                <Row>
                    <TextBox>
                        <TextField
                            id="outlined-multiline-static"
                            label="Name"
                            fullWidth
                            disabled
                            value={(user?.initials + " " + user?.lastName)}
                        />
                    </TextBox>
                    <TextBox>
                        <TextField
                            id="outlined-multiline-static"
                            label="Student Number"
                            fullWidth
                            disabled
                            value={user.studentNo}
                        />
                    </TextBox>
                </Row>
                <Row>
                    <TextBox>
                        <TextField
                            id="outlined-multiline-static"
                            label="Hostel"
                            fullWidth
                            disabled
                            value={user.hostel.name}
                        />
                    </TextBox>
                    <TextBox>
                        <TextField
                            id="outlined-multiline-static"
                            label="Faculty"
                            fullWidth
                            disabled
                            value={user.faculty}
                        />
                    </TextBox>
                </Row>
                <Row>
                    <TextBox>
                        <TextField
                            id="outlined-multiline-static"
                            label="Level"
                            fullWidth
                            disabled
                            value={user.level}
                        />
                    </TextBox>
                    <TextBox>
                        <TextField
                            id="queryType"
                            select
                            label="Query Type"
                            value={queryType}
                            required={true}
                            onChange={e => { setQueryType(e.target.value) }}
                            style={{ width: '100%' }}
                        >
                            {queryTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </TextBox>
                </Row>
                <Row>
                    <TextBox>
                        <TextField
                            id="outlined-multiline-static"
                            label="Query"
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                            defaultValue=""
                        />
                    </TextBox>
                </Row>
                <Row>
                    {
                        pending
                            ? <Buttons>
                                <Button type="submit" disabled variant="contained" size="small" endIcon={<AiOutlineSend />}>
                                    <ClipLoader size={20} color='#fff' />
                                </Button>
                                <Buttons>
                                    <Button onClick={reset} color='error' variant="contained" endIcon={<AiOutlineCloseCircle />}>Clear</Button>
                                </Buttons>
                            </Buttons>
                            :
                            <Buttons>
                                <Button type="submit" variant="contained" size="small" endIcon={<AiOutlineSend />}>
                                    SUBMIT
                                </Button>
                                <Buttons>
                                    <Button onClick={reset} color='error' variant="contained" endIcon={<AiOutlineCloseCircle />}>Clear</Button>
                                </Buttons>
                            </Buttons>
                    }
                    
                    
                    
                </Row>
            </Form>
            <BackwordButton nav1={props.nav2} />
        </Container>
    );
}

export default CreateQuery;