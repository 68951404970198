import React from 'react'
import UserHome from '../../Components/shared/Home/UserHome'

const SOHome = (props) => {
  return (
    <>
    <UserHome Navs1={props.Navs} view2Prop1 = {props.view2Prop}/>
</>
  )
}

export default SOHome