import {eventImages} from "../../Data/Content";
import {
        EventContainer,
        Event,
        ImageSection,
        Description,
        P,
        Image,
        Header,
        Head
} from "./EventsStyle";
const Events = () => {
    return ( 
        <EventContainer>
            <Head data-aos="fade-up">
                <Header>
                    Events
                </Header>
            </Head>
            <Event>
                <ImageSection>
                    <Image src={eventImages[0].photo}/>
                </ImageSection>
                <Description>
                    <P>    "The 'Pandama' musical event, organized by students from Meddawatta Hostel, was a huge success. The evening was filled with melodious entertainment from a lineup of talented performers. The event was a hit with music lovers of all ages and was enjoyed by all who attended. Thank you to all who participated and made this event possible."</P>
                </Description>
            </Event>
            <Event>
                <ImageSection>
                    <Image src={eventImages[1].photo}/>
                </ImageSection>
                <Description>
                    <P>The 'Drug Awareness' event, held at  Meddawatta Hostel, aimed to inform students about the serious consequences of drug usage.
                    The event was well-attended and featured a keynote speech from the Deputy Vice Chancellor of the university. The event was a success in raising awareness about the dangers of drug usage and in educating students about the importance of leading a healthy, drug-free lifestyle. 
                    </P>
                </Description>
            </Event>
            {/* <Event>
                <ImageSection>
                    <Image src={eventImages[2].photo}/>
                </ImageSection>
                <Description>
                    <P>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor eaque explicabo veritatis, laudantium eveniet quod maiores ut in alias tenetur. A modi enim quis voluptatibus repellat eius corrupti ad quam consequatur hic. Dolorum, quasi aliquid.</P>
                </Description>
            </Event> */}
        </EventContainer>
     );
}
 
export default Events;