import { useEffect, useState } from "react";
import AcademicWardens from "../Components/AcademicWardens/AcademicWardens";
import Cover from "../Components/Cover/Cover";
import DropDown from "../Components/DropDown/DropDown";
import SubWardens from "../Components/SubWardnes/SubWardens";

import styled from "styled-components";

import { wellamadamaAW, engineeringAW, medicineAW, technologyAW, agriAW, allied_health_sciAW } from "../Data/AcademicWardens";

import { wellamadamaSW, engineeringSW, medicineSW, technologySW, agriSW, allied_health_sciSW } from "../Data/SubWardens";

const DropDownContainer = styled.div`
    ${'' /* background-color:red ; */}
    display:flex ;
    justify-content:center ;
    margin-top: 2%;
`

const WardensandSubWardens = (props) => {

    const [premise, setPremise] = useState('wellamadama');

    let academicWardens = [];

    let subWardens = []

    switch (premise) {
        
        case 'wellamadama':
            academicWardens = wellamadamaAW;
            subWardens = wellamadamaSW;
            break;
        case 'engineering':
            academicWardens = engineeringAW;
            subWardens = engineeringSW;
            break;
        case 'medicine':
            academicWardens = medicineAW;
            subWardens = medicineSW;
            break;
        case 'technology':
            academicWardens = technologyAW;
            subWardens = technologySW;
            break;
        case 'agriculture':
            academicWardens = agriAW;
            subWardens = agriSW;
            break;
        case 'allied health science':
            academicWardens = allied_health_sciAW;
            subWardens = allied_health_sciSW;
            break;

        default:
            academicWardens = wellamadamaAW;
            subWardens = wellamadamaSW;
    }
    
    useEffect(() => {
    }, [premise])

   
      

    return (
        <div>
            <Cover Cover1text={props.Covertext} CoverImage1={props.CoverImage} />
            <DropDownContainer>
                <DropDown setPremise={setPremise} premise={premise} />
            </DropDownContainer>
            <AcademicWardens users={academicWardens} />
            <SubWardens users={subWardens} />
        </div>
    );
}
 
export default WardensandSubWardens;