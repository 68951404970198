export const wellamadamaAW = [
    {
        name: 'Dr. K. A. Sunanda. Kodikara-Arachchi',
        image: require('./../images/subwarden & academic wardens/wellamadama/academic wardens/image_1.jpg'),
        phone: '+94777116347',
        email:'sunandaruh@gmail.com'
    },
    {
        name: 'Dr. W. S. Hemalika',
        image: require('./../images/subwarden & academic wardens/wellamadama/academic wardens/image_2.jpg'),
        phone: '+94714936125',
        email:'hemalika@chem.ruh.ac.lk'
    },
]

export const engineeringAW = [
    {
        name: 'Dr. Chaminda Karunasena',
        image: require('./../images/subwarden & academic wardens/engineering/academic wardens/chaminda karunasena.png'),
        phone: '+94718481683-',
        email:'chaminda@mme.ruh.ac.lk'
    },
    {
        name: 'Dr. S.N. Malkanthi',
        image: require('./../images/subwarden & academic wardens/engineering/academic wardens/n malkanthi.png'),
        phone: '+94772869264',
        email:'snmalkanthi@cee.ruh.ac.lk'
    }
]
export const medicineAW = [
    {
        name: 'Dr. Lanka Dassannayake',
        image: require('./../images/subwarden & academic wardens/medicine/acadmeic wardens/lanka_dassanayake.png'),
        phone: '+94777903792',
        email:'lanka.dasanayake@yahoo.com,'
    },
    {
        name: 'Dr. Gayani Alwis',
        image: require('./../images/subwarden & academic wardens/medicine/acadmeic wardens/gayani_alwis.png'),
        phone: '+94718676010',
        email:'alwisgayani@yahoo.com,'
    },
]


export const technologyAW = [
    {
        name: 'Dr. A. M. Ajward',
        image: require('./../images/subwarden & academic wardens/technology/academic wardens/a.m.ajward.png'),
        phone: '-',
        email:'-'
    },
    {
        name: 'Dr. (Mrs.) KMW Rajawatta',
        image: require('./../images/subwarden & academic wardens/technology/academic wardens/kmw_rajawatta.png'),
        phone: '-',
        email:'-'
    }
]


export const agriAW = [
    {
        name: '-',
        image: require('./../images/subwarden & academic wardens/agriculture/academic wardens/default.png'),
        phone: '-',
        email:'-'
    },
    {
        name: '-',
        image: require('./../images/subwarden & academic wardens/agriculture/academic wardens/default.png'),
        phone: '-',
        email:'-'
    }
]


export const allied_health_sciAW = [
    {
        name: 'Dr.Aruna Dissanayake',
        image: require('./../images/subwarden & academic wardens/allied health science/academic wardens/aruna dissanayake.png'),
        phone: '+94718022319',
        email:'arunasd@chem.ruh.ac.lk'
    },
    {
        name: 'Mrs.Vindya Pathiraja',
        image: require('./../images/subwarden & academic wardens/allied health science/academic wardens/vindya pathiraja.png'),
        phone: '+94703991765',
        email:'vindyampathi@gmail.com'
    }
]