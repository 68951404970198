import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
    BackButtonContainer
}from './BackButtonElements'
const BackwordButton = (props) => {
    const scrollToTop = ()=>{
        if(window.pageYOffset > 50){
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
      }
    return ( 
        <BackButtonContainer onClick={scrollToTop}>
            <Button variant="contained" size="small" color='warning'><Link to={props?.nav1?.nav || '/login'} className="lin" >BACK</Link></Button>
        </BackButtonContainer>
     );
}
 
export default BackwordButton;