import React from 'react'
import ReactiveUserForm from '../../Components/shared/Reactvie Users/ReactiveUserForm'
import { Container,H1 } from '../../Components/shared/Reactvie Users/ReactiveUserPageElements'

const SubWardenReactiveUsers = () => {
  return (
      <Container>
          <H1>Re-Active Students</H1>
          <ReactiveUserForm url={ '/api/v1/students/reActiveStudent'} />
    </Container>
  )
}

export default SubWardenReactiveUsers