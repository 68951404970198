import React from 'react'
import CreateQuery from '../../Components/CreateQuery/CreateQuery'

const StudentCreateQuery = (props) => {
  return (
      <>
          <CreateQuery nav2={props.nav3}/>
    </>
  )
}

export default StudentCreateQuery