import React, { useState } from 'react'
import { Container,H1,Form, FormGroup, Label } from './ForgotPasswordFromElemets'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import useAlert from './../../Hooks/useAlert';
import ClipLoader from "react-spinners/ClipLoader";
import * as A from './../../Components/alert/Alert_Types'

const ForgotPasswordForm = () => {

    const [email, setEmail] = useState('');
    const { setAlert } = useAlert();

    const [pending, setPending] = useState(false);


    const onSubmit = async(e) => {
        e.preventDefault();
        setPending(true)
        try {
            const res = await axios.post('/api/v1/auth/forgotPassword',{email});
            // console.log(res.data);
            if (res.data.status === 'success') {
                setAlert(`${res.data.message} 👍`, A.SUCCESS, 5000);
            }
            setPending(false)
            setEmail('');

        } catch (err) {
            // console.log(err.response.data);
            // show alert error
            setAlert(`${err?.response.data.message} 🙄🤨`, A.ERROR, 5000);
            setPending(false)
        }
    }

    return (
        <Container>
            <H1>FORGOT PASSWORD </H1>
            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <TextField
                        type='email'
                        margin='normal'
                        fullWidth
                        id="outlined-basic"
                        label="Enter Email to send Password Reset Link"
                        variant="outlined" 
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                        required
                    />
                    {pending
                        ? <Button type='submit' fullWidth variant="contained"  > <ClipLoader size={20} color='#FFF' />  </Button>
                        :<Button type='submit' fullWidth variant="contained" endIcon={<SendIcon />} >Send</Button>  
                    }
                    
                </FormGroup>
            </Form>
        </Container>
    )
}

export default ForgotPasswordForm