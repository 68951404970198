import React,{useState} from 'react'
import { Container, H1, Form,FormGroup,SubmitBtnRow } from '../../3_subwarden/add remove comitte memeber form/AddRemoveElements'
import TextField from '@mui/material/TextField';
import { ERROR, SUCCESS } from './../../alert/Alert_Types';
import { ClipLoader } from 'react-spinners';
import { AiOutlineUserAdd, AiOutlineUserDelete } from "react-icons/ai";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import useAlert from '../../../Hooks/useAlert';
import BackwordButton from '../../Button/BackButton/BackButton';
const AddRemoveForm = (props) => {

    const [email, setEmail] = useState('');
    const [pending, setPending] = useState(false);
    const [action, setAction] = useState('add');

    const { setAlert} = useAlert();

    const onSubmit = async e => {
        e.preventDefault();

        setPending(true);
        let url = '';
        if (action === 'add') {
            url = `/api/v1/students/newCommitteMember/${email}`;
        }
        if (action === 'remove') {
            url = `/api/v1/students/removeCommitteMember/${email}`;
            
        }

        try {
            const res = await axios.patch(url);
            // console.log(res);
            if (res.data.status === 'success') {
                setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
            }
            setPending(false);

        } catch (err) {
            // console.log(err.response?.data);
            setAlert(`${err?.response.data.message} 🙄🤨`, ERROR, 5000);
            setPending(false);
            
        }

        setEmail('');


    }

    return (
        <Container>
            <H1>add || remove committe member</H1>
            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <TextField
                        type='email'
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormControl sx={{ m: 1, minWidth: 320 }} >
                    <InputLabel id="demo-simple-select-label">Select Action</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={action}
                        label="Select Action"
                        required
                        onChange={e => setAction(e.target.value)}
                    >
                        <MenuItem value={'add'}>Add Committe Member</MenuItem>
                        <MenuItem value={'remove'}>Remove Committe Memeber</MenuItem>
                            
                    </Select>
                </FormControl>
                <SubmitBtnRow>
                    {pending
                        ? <Button type='submit' variant="contained" disabled endIcon={!pending && <AiOutlineUserAdd />} >
                            <ClipLoader size={20} color={'#1ba3de'} />
                        </Button>
                        : <Button type='submit' variant="contained" endIcon={!pending && <AiOutlineUserAdd />} >
                            {action} User
                        </Button>
                        
                    }
                    

                </SubmitBtnRow>
                <BackwordButton nav1={props.nav2}/>
            </Form>
        </Container>
    )
}

export default AddRemoveForm