import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { Container,Header,SpinnerContainer,Top ,LinkRow } from './ViewQueriesElements'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { AiOutlineEye,AiOutlineFileProtect,AiOutlineHourglass,AiOutlineClose,AiOutlineCheck,AiOutlineTool } from "react-icons/ai";
import useAuth from '../../../Hooks/useAuth';
import BackwordButton from '../../Button/BackButton/BackButton';
const ViewQueries = (props) => {

    const navigate = useNavigate();
    const { user } = useAuth();

   
    let rows = [];   
           
    const queries = props.queries.data.myQueries || props.queries.data.forwardedQueries || props.queries.data.queries;
    rows = queries?.map(query => {
        return {
            id: query?.id,
            name: query?.createdUser?.firstName,
            queryType: query?.queryType,
            message: query?.message,
            status: query?.status,
            currentlyForwardedToUser:query?.currentlyForwardedToUser
        }
    })

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
    return (
        <>
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>#</StyledTableCell>
                        <StyledTableCell>Created User</StyledTableCell>
                        <StyledTableCell align="left">Query Type</StyledTableCell>
                        <StyledTableCell align="center">Message</StyledTableCell>
                        <StyledTableCell align="center">Status</StyledTableCell>
                        <StyledTableCell align="right">Actions</StyledTableCell>
                            
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length===0 && <h4>No Data to display !</h4>}
                    {rows?.map((row,i) => (
                        <TableRow hover={true}
                            // component={Link} to={`/admin-update-hostel/`}
                            // key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <StyledTableCell component="th" scope="row">
                                {i+1}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                {row.queryType}
                                {row.queryType === 'maintenance' &&
                                    <IconButton>
                                        <AiOutlineTool />
                                    </IconButton>
                                }
                                
                            </StyledTableCell>
                            <StyledTableCell align="left">{`${row.message.slice(0,25)} ....`}</StyledTableCell>
                            <StyledTableCell align="center">
                                {row.status}
                                {row.status === 'pending' &&
                                    <IconButton>
                                        <AiOutlineHourglass />
                                    </IconButton>
                                }
                                {row.status === 'rejected' &&
                                    <IconButton>
                                        <AiOutlineClose />
                                    </IconButton>
                                }
                                {row.status === 'solved' &&
                                    <IconButton>
                                        <AiOutlineCheck />
                                    </IconButton>
                                }
                                
                            </StyledTableCell>
                            <StyledTableCell align="right">
                                {row.status === 'pending' && row.currentlyForwardedToUser?.id === user?.id
                                    ? <>
                                        <Tooltip title="Take Action">
                                            <IconButton onClick={()=>{navigate(`/${user.role}/handle-query/${row.id}`)}} >
                                                <AiOutlineFileProtect />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="View">
                                            <IconButton onClick={()=>{navigate(`/${user.role}/view-query/${row.id}`)}}>
                                                <AiOutlineEye />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                    : <Tooltip title="View">
                                        <IconButton onClick={()=>{navigate(`/${user.role}/view-query/${row.id}`)}}>
                                            <AiOutlineEye />
                                        </IconButton>
                                    </Tooltip>
                                }
                                
                                
                            </StyledTableCell>
                               
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <BackwordButton nav1={props.nav2}/>
        </>
        
    );
}
 
export default ViewQueries;