import Home  from '../../Components/shared/Home/UserHome'
import React from 'react'

// import ViewStudent from '../../Components/shared/viewStudent/ViewStudents';
import UserHome from '../../Components/shared/Home/UserHome';

const StudentHome = (props) => {
  return (
    <>
      {/* <h1>This is # student home # page.</h1>
      <p>display currently logged in students queries.</p> */}
      {/* <ViewStudent/> */}
      <UserHome Navs1 = {props.Navs} view2Prop1 = {props.view2Prop}/>
    </>
  );
}

export default StudentHome