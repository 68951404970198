import React, {useState } from 'react'
import useFetch from '../../../Hooks/useFetch';
import FormControl from '@mui/material/FormControl';
import { Container,FormGroup,Form,SubmitBtnRow } from './UpdateStudentFormElements';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { GrUpdate } from "react-icons/gr";
import { BiError } from "react-icons/bi";

import axios from 'axios';
import useAlert from '../../../Hooks/useAlert';
import { ERROR, SUCCESS } from './../../alert/Alert_Types';
import { useNavigate } from "react-router-dom";
import useAuth from '../../../Hooks/useAuth';
import { ClipLoader } from 'react-spinners';
import BackwordButton from '../../Button/BackButton/BackButton';

//Created by Mudith (7/14/2022)

const UpdateStudentForm = (props) => {

  const{loadUser}=useAuth()

  //All the Faculties must be called here from the backend (START)
const Faculties = [
  {
    value: 'science',
    label: 'Science',
  },
  {
    value: 'agriculture',
    label: 'Agriculture',
  },
  {
    value: 'allied health science',
    label: 'Allied Health Science',
  },
  {
    value: 'engineering',
    label: 'Engineering',
  },
  {
    value: 'fisheries and marine science',
    label: 'Fisheries And Marine Science',
  },
  {
    value: 'humanities and social science',
    label: 'Humanities And Social Science',
  },
  {
    value: 'management and finance',
    label: 'Management And Finance',
  },
  {
    value: 'medicine',
    label: 'Medicine',
  },
  {
    value: 'technology',
    label: 'Technology',
  }
];
//All the Faculties must be called here from the backend (END)

//Defining Levels of a student *HardCoded* (START)
const Levels = [
  {
    value: '1',
    label: '1 (One)',
  },
  {
    value: '2',
    label: '2 (Two)',
  },
  {
    value: '3',
    label: '3 (Three)',
  },
  {
    value: '4',
    label: '4 (Four)',
  }
];
//Defining Levels of a student (END)

//Defining Hostel fee (START)
const HostelFees = [
  {
    value: 'true',
    label: 'True'
  },
  {
    value: 'false',
    label: 'False'
  }
];
//Defining Hostel fee (START)
let navigate = useNavigate();
//Hostels must be called from the back end (START)
 //fetch all hostels
const {data:hostels, isPending:ahPending, isError:ahError } = useFetch('/api/v1/hostels');

//Hostels must be called from the back end (END)
  const [firstName, setFirstName] = React.useState(props.user.firstName || '');
  const [lastName, setLastName] = React.useState(props.user.lastName || '');
  const [initials, setInitials] = React.useState(props.user.initials || '');
  const [studentNo, setStudentNo] = React.useState(props.user.studentNo || '');
  const [roomNo, setRoomNo] = React.useState(props.user.roomNo || '');
  const [committeeMember, setCommitteeMember] = React.useState(props.user?.committeeMember);
  const [specialRemarks, setSpecialRemarks] = React.useState(props.user.specialRemarks || '');

  const [faculty, setFaculty] = React.useState(props.user.faculty);
  const [level, setLevel] = React.useState(props.user.level);
  const [hostel, setHostel] = React.useState(props.user.hostel.id);
  const [hostelFee, setHostelFee] = React.useState(props.user.hostelFee);

  const [pending, setPending] = useState(false);
  const { setAlert} = useAlert();

  const onUpdate = async e => {
    e.preventDefault();

    const formData = { firstName, lastName, initials, studentNo, faculty, level, hostel, roomNo, hostelFee, specialRemarks, committeeMember};
    setPending(true);
    try {
      // console.log('inside the try');
        const res = await axios.patch('/api/v1/students/updateMe', formData);
        // console.log(formData);
        // console.log({res});
        if (res.status === 201) {
            setPending(false);
            setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
      }
      
      loadUser();

    } catch (error) {
        // console.log(error);
        // console.log(error.response.data.message);
        setAlert(`${error?.response.data.message} 😥`, ERROR, 5000);
        setPending(false);
    }
  }
  
  const onDeactivate = async e => {

    setPending(true);

    try {
      const res = await axios.patch('/api/v1/students/deactivateMe', {});
      if (res.status === 200) {
        setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
      }
      loadUser();
    } catch (err) {
      setAlert(`${err?.response.data.message} 😥`, ERROR, 5000);
    }

  }

  return (
    <>
      <Button
        style={{ width: 350, margin: '0 0 2rem auto', padding: '0.5rem 0' }}
        type='submit'
        variant="contained"
        color='error'
        size="small"
        onClick={() => {
          const confirmed = window.
            confirm(
              'Are you sure want to deactivate account? Cannot undo this action.\nYou will need to contact sub warden to reactive account.\n '
            );

          if (confirmed) {
            // call the function to deactivate account
            onDeactivate();
          } else {
            // nothing will happen
          }

        }}
      >
        Deactivate Account 
      </Button>
      <Form onSubmit={onUpdate}>
      <FormGroup>
        <TextField 
          id="firstName" 
          label="First Name" 
          variant="outlined" 
          size='small' 
          value = {firstName}
          onChange={e => setFirstName(e.target.value)}
          style = {{width: 350}}/>
      </FormGroup>
      <FormGroup>
        <TextField
            id="hostel"
            select
            label="hostel"
            // disabled={false}
            value={hostel}
            onChange={e=>{setHostel(e.target.value)}}
            style = {{width: 350}}
            
            //helperText="Please select your currency"
          >
              {ahPending && <p> ...  still loading data 🤞 ....</p>}
              {ahError && <p> ...  error while fetching data 🙄 ....</p>}
              {hostels && hostels.data.hostels?.map((user, id) => {
                  return (
                      <MenuItem key={id} value={user.id}>{user.name}</MenuItem>
                      
                  )
              })}
              {hostels && hostels?.data.results === 0 ? <p>None Available</p> : <span></span>}
          </TextField>
      </FormGroup>
        
      <FormGroup>
        <TextField 
          id="lastName" 
          label="Last Name" 
          variant="outlined" 
          size='small' 
          value = {lastName}
          onChange={e=>{setLastName(e.target.value)}}
          style = {{width: 350}}/>
      </FormGroup>
      <FormGroup>
        <TextField
            id="roomNo" 
            label="Room No" 
            variant="outlined" 
            size='small' 
            value = {roomNo}
            onChange={e=>{setRoomNo(e.target.value)}}
            style = {{width: 100,  float: "left"}}/>
      </FormGroup>

      <FormGroup>
        <TextField 
          id="initials" 
          label="Initials" 
          variant="outlined" 
          size='small' 
          value = {initials}
          onChange = {e=>{setInitials(e.target.value)}}
          style = {{width: 350}}/>
      </FormGroup>
      {/* <FormGroup>
        <TextField
            id="hostelFee"
            select
            label="Hostel Fee"
            value={hostelFee}
            onChange={e=>{setHostelFee(e.target.value)}}
            style = {{width: 150, float: "left"}}
            //helperText="Please select your currency"
          >
            {HostelFees.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
      </FormGroup> */}

      <FormGroup>
        <TextField 
          id="studentNo" 
          label="Student No" 
          variant="outlined" 
          size='small' 
          value = {studentNo}
          onChange = {e=>{setStudentNo(e.target.value)}}
          style = {{width: 350}}/>
      </FormGroup>
      <FormGroup>
        <TextField 
          id="committeeMember" 
          label="Committee Member" 
          variant="outlined" 
          size='small' 
          disabled={true} 
          value = {committeeMember}
          onChange = {e=>{setCommitteeMember(e.target.value)}}
          style = {{width: 350}}/>
      </FormGroup>

      <FormGroup>
        <TextField
            id="faculty"
            select
            label="faculty"
            value={faculty}
            onChange={e=>{setFaculty(e.target.value)}}
            style = {{width: 350, float: "left"}}
            
            //helperText="Please select your currency"
          >
            {Faculties.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
        <FormGroup>
          <TextField
            id="level"
            select
            label="level"
            value={level}
            onChange={e=>{setLevel(e.target.value)}}
            style = {{width: 150, float: "left"}}
            //helperText="Please select your currency"
          >
            {Levels.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField> 
      </FormGroup>

      <FormGroup>
        <TextField 
          id="specialRemarks" 
          label="Special Remarks" 
          multiline rows={5} 
          style = {{width: '100%'}} 
          value = {specialRemarks} 
          onChange={e=>{setSpecialRemarks(e.target.value)}}
        />
      </FormGroup>
      <FormGroup>
      </FormGroup>     
      <SubmitBtnRow>
        
          <Button variant="contained" size="small" color='warning' onClick={() => {navigate('/student', { replace: true })} }>Discard</Button>
          {/* Discard Button is same as back Button */}
          {/* <Button type='submit' variant="contained" color='success' size="small">UPDATE</Button> */}
          {pending
            ? <Button type='submit' variant="contained" color='success' size="small"> <ClipLoader size={20} color='#FFF' /> </Button>
            :<Button type='submit' variant="contained" color='success' size="small">UPDATE</Button>
          }
          
      </SubmitBtnRow>
      </Form>
      <BackwordButton nav1={props.nav2}/>
    </>
  )
}

export default UpdateStudentForm