import React, { useState } from 'react'
import AdminViewUsersTable from '../../Components/1_admin/view users/AdminViewUsersTable'
import useFetch from '../../Hooks/useFetch'
import { Container, H1,SpinnerContainer,DropDownContainer } from '../../Components/1_admin/view users/AdminViewUsersTableElements';
import ClipLoader from "react-spinners/ClipLoader";


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SUCCESS, ERROR } from './../../Components/alert/Alert_Types';
import useAlert from './../../Hooks/useAlert';
import axios from 'axios';

const AdminViewUsers = (props) => {

  const [query, setQuery] = useState('')
  
  const { data, isPending, isError } = useFetch(`/api/v1/admin-users?${query}`);

  return (
    <>
      <Container>
        <H1>USERS</H1>
        <DropDownContainer>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select User Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={query}
              label="Select User Type"
              onChange={e => setQuery(e.target.value)}
            >
              <MenuItem value={''}>All</MenuItem>
              <MenuItem value={'role=academic_warden'}>Academic Warden</MenuItem>
              <MenuItem value={'role=sub_warden'}>Sub Warden</MenuItem>
            </Select>
          </FormControl>
        </DropDownContainer>
        
        {isError && <h1>Something went wrong  ... 😥</h1>}
        {isPending && <SpinnerContainer><ClipLoader size={50} /></SpinnerContainer>}
        {data && <AdminViewUsersTable setQuery={setQuery} data={data} nav2={props.nav3}/>}

        
      </Container>
    </>
  )
}

export default AdminViewUsers