import React from 'react'
import HandleQuery from '../../Components/shared/handleQuery/HandleQuery'
import { Container,Top,Header ,SpinnerContainer} from '../../Components/shared/handleQuery/HandleQueryPageElements'
import useFetch from './../../Hooks/useFetch';
import ClipLoader from 'react-spinners/ClipLoader'
import { useParams } from 'react-router-dom';
const AcademicWardenHandleQuery = (props) => {

  const { id } = useParams();

  const { data, isPending, isError } = useFetch(`/api/v1/queries/${id}`);

  const query = data?.data?.query;
  return (
    <Container>
      <Top>
        <Header>Handle Query</Header>
      </Top>
      {isPending && <SpinnerContainer> <ClipLoader />  </SpinnerContainer>}
      {isError && <h2>Something went wrong .... 😥 </h2>}
      {data && <HandleQuery query={query} id={id} nav2={props.nav3}/>}
      {/* <HandleQuery/> */}
    </Container>
  )
}

export default AcademicWardenHandleQuery