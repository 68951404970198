import React from 'react'
import ViewStudent from '../../Components/shared/viewStudent/ViewStudent';
import ClipLoader from "react-spinners/ClipLoader";
import useFetch from '../../Hooks/useFetch';
import { Container, H1,SpinnerContainer } from '../../Components/shared/viewStudent/ViewStudentElements';

const AcademicWardenViewStudent = (props) => {

  //const { data, isPending, isError } = useFetch('/api/v1/students/62cae05843a68209a190ec5d');

  //console.log(data?.results);
  // console.log(data?.data?.hostels);
  return (
      <>
      {/* <h1>this is # academic warden view student # page </h1>
      <p>full details of a selected student from currently logged in academic warden's , will display here</p>
       */}
      {/* <Container>
        <H1>Student</H1>

        {isError && <h1>Something went wrong  ... 😥</h1>}

        {isPending && <SpinnerContainer><ClipLoader size={50} /></SpinnerContainer>}
        
        {data && <ViewStudent data={data} />}
      </Container> */}

      <ViewStudent nav2={props.nav3}/>
      
    </>
  );
}

export default AcademicWardenViewStudent