import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    width:70% ;
    max-height:100vh ;
    ${'' /* overflow:scroll ; */}
    border :1px solid #339BFF;
    margin:13% auto ;
    padding:2rem ;
    display:flex ;
    flex-direction:column ;
    border-radius:30px ;
    @media screen and (max-width: 800px){
        margin-top: 30%;
        width: 70%;
        padding: 5%;
    }
`;
export const Top = styled.div`
    display:grid;
    
`
export const Header = styled.h2`
    font-size: 30px;
    justify-content: center;
    text-transform: uppercase;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    @media screen and (max-width: 800px){
        font-size: 1rem;
    }
`
export const H1 = styled.h1`
        text-align: center;
        font-size: 2rem;
        color: #49483E;
        letter-spacing:8px;
        font-weight: bold;
        position: relative;
        left: 0;
        display: inline;
        color: #49483E;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
        ${'' /* background-image: linear-gradient(
        to right,
        #339BFF5d,
        #339BFF
        );
        transition: all 0.2s;
        -webkit-background-clip: text; */}
    `

export const SpinnerContainer = styled.div`
    width:5rem ;
    height:5rem;
    margin: 5rem auto ;
    ${'' /* background-color:red ; */}
`

export const LinkRow = styled(Link)`

`


export const DropDownContainer = styled.div`
    width:40% ;
    margin:1rem 0 ;
    ${'' /* background-color:red ; */}
    @media screen and (max-width: 800px){
        width: 100%;
    }
`