import React, { useState } from 'react';

import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import {
  Container,
  Top,
  Header,
  Query,
  Status,
  Text,
  BackButton,
  HR,
  Buttons
} from './HandleQueryElements'
import axios from 'axios';
import { ERROR, SUCCESS } from './../../alert/Alert_Types';
import useAlert from '../../../Hooks/useAlert';
import useAuth from '../../../Hooks/useAuth';
import ClipLoader from 'react-spinners/ClipLoader';
import IconButton from '@mui/material/IconButton';
import { MdPendingActions } from "react-icons/md";
import { AiOutlineTool } from "react-icons/ai";
import BackwordButton from '../../Button/BackButton/BackButton';

const HandleQuery = (props) => {


  // console.log({ 'query': props.query });
  const [comment, setComment] = useState();

  const [pending, setPending] = useState({
    solve: false,
    forward: false,
    reject: false
  });

  const { setAlert } = useAlert();
  const { user } = useAuth();

  const onForward = async (e) => {
    e.preventDefault();
    const formData = { forwardMessage: comment+`\nDate: ${new Date().toJSON().slice(0, 10)}` };
    setPending({ solve: false, forward: true, reject: false });
    try {
      const res = await axios.patch(`/api/v1/queries/forward/${props.id}`, formData)
      if (res.status === 201) {
        // setPending(false);
        setPending({ solve: false, forward: false, reject: false });

        setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.response.data.message);
      setAlert(`${error?.response.data.message} 🙄🤨`, ERROR, 5000);
      // setPending(false);
      setPending({ solve: false, forward: false, reject: false });

    }
  }

  const onReject = async (e) => {
    e.preventDefault();
    const formData = { rejectedComment: comment+`\nDate: ${new Date().toJSON().slice(0, 10)}` };
    setPending({ solve: false, forward: false, reject: true });

    try {
      const res = await axios.patch(`/api/v1/queries/reject/${props.id}`, formData)
      if (res.status === 201) {
        // setPending(false);
        setPending({ solve: false, forward: false, reject: false });

        setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.response.data.message);
      setAlert(`${error?.response.data.message} 🙄🤨`, ERROR, 5000);
      // setPending(false);
      setPending({ solve: false, forward: false, reject: false });

    }
  }

  const onSolve = async (e) => {
    e.preventDefault();

    const formData = { solvedComment: comment+`\nDate: ${new Date().toJSON().slice(0, 10)}` };
    setPending({ solve: true, forward: false, reject: false });

    try {
      const res = await axios.patch(`/api/v1/queries/solve/${props.id}`, formData)
      if (res.status === 201) {
        // setPending(false);
        setPending({ solve: false, forward: false, reject: false });

        setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
      }
    } catch (error) {
      // console.log(error);
      // console.log(error.response.data.message);
      setAlert(`${error?.response.data.message} 🙄🤨`, ERROR, 5000);
      // setPending(false);
      setPending({ solve: false, forward: false, reject: false });

    }

    
  }

  return (
    
    <Query>
      <Status>
        {props.query.status}
        {props.query.status === 'pending' &&
          <IconButton aria-label="delete"  >
            <MdPendingActions color='#f7b705' />
          </IconButton>
        }
        {props.query.queryType === 'maintenance' &&
          <IconButton aria-label="delete"  >
            <AiOutlineTool color='#f7b705' />
          </IconButton>
        }
      </Status>
      <Text>Message</Text>
      <br />
      <div>
        <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={6}
          fullWidth
          value={props.query.message}
        // defaultValue=""
        />
        <br />
      </div>
      <HR />
      <br />
      <div>
        <Text>Comment</Text> <br />
        <TextField
          id="outlined-multiline-static"
          label="Comment"
          multiline
          rows={4}
          fullWidth
          required
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
      </div>
      <Buttons>
        <ButtonGroup variant="outlined" aria-label="outlined primary button group">
          
          {user.role !== 'vc' && <Button type='submit' onClick={onForward} color='success'>{pending.forward ? <ClipLoader size={20} /> : 'Forward'}</Button>}
          <Button type='submit' onClick={onSolve} >{pending.solve ? <ClipLoader size={20} /> : 'solve'}</Button>
          <Button type='submit' onClick={onReject} color='error'>{pending.reject ? <ClipLoader size={20} /> : 'reject'}</Button>
        </ButtonGroup>
      </Buttons>
      <BackwordButton nav1={props.nav2}/>

    </Query>
    
  )
};

export default HandleQuery