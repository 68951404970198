import ContactUs from "../Components/Contact/ContactUs";

const ContactUsPage = () => {
    return ( 
        <>
            <ContactUs/>
        </>
     );
}
 
export default ContactUsPage;