import React from 'react'
import AdmiAddUserForm from '../../Components/1_admin/add user form/AdmiAddUserForm'

const AdminAddUsers = (props) => {
  return (
    <>
      <AdmiAddUserForm nav2={props.nav3}/>
    </>
  )
}

export default AdminAddUsers