import { Link } from "react-router-dom"
import styled from "styled-components"

export const Container = styled.div`

    width:80% ;
    min-height:50vh ;
    margin:10rem auto ;
    ${'' /* background-color: red; */}

`

export const TableContainer = styled.div`
    background-color:red ;
    max-height: 200px;
    width:70% ;

    overflow: scroll;
`