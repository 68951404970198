import React from 'react'
import AddRemoveForm from '../../Components/3_subwarden/add remove comitte memeber form/AddRemoveForm'


const SubWardenCommitteMember = (props) => {
  return (
      <>
       <AddRemoveForm nav2={props.nav3}/>   
    </>
  )
}

export default SubWardenCommitteMember