import React from 'react'

import { Container,TableContainer as T } from './TemplateElements'

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { GrUpdate } from "react-icons/gr";
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Template = () => {

    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
    ];
    
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }
  return (


    <Container>
          
      
      <h1>UNAUTHORIZED USER</h1>

      {/* <Button variant="contained" size="small">SUBMIT</Button> <br /> <br />
      <Button variant="contained" color='success' size="small">UPDATE</Button> <br /><br />
      <Button variant="contained" color='error' size="small" endIcon={<DeleteIcon />}>DELETE</Button> <br /><br />
      <Button variant="outlined" color='error' size="small">CLEAR</Button> <br /><br />
      <Button variant="contained" size="small">SEARCH</Button> <br /> <br />
      <Button variant="contained" size="small" color='warning'>BACK</Button> <br /> <br />
          
      <hr />
      <br /> <br />
          
      <ButtonGroup variant="outlined" aria-label="outlined primary button group">
        <Button color='success'> FORWARD</Button>
        <Button >SOLVE</Button>
        <Button color='error'>REJECT</Button>
      </ButtonGroup>
      <br /> <br />

      <hr />
      <br /> <br />

      <br /> <br />
      <TextField id="outlined-basic" label="Name" variant="outlined" />  <br /> <br />
      <TextField
        id="outlined-multiline-static"
        label="Multiline"
        multiline
        rows={4}
        fullWidth
        defaultValue="Default Value"
      /> <br /> <br />
            
      <hr />
      <hr />


      <T>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Dessert (100g serving)</TableCell>
                <TableCell align="right">Calories</TableCell>
                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                <TableCell align="right">Protein&nbsp;(g)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.calories}</TableCell>
                  <TableCell align="right">{row.fat}</TableCell>
                  <TableCell align="right">{row.carbs}</TableCell>
                  <TableCell align="right">{row.protein}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

          
      </T>




      buttons : [submit,update,delete], form_clear, [forward,reject,solve], search, back
      text Boxes : default,dropDown,text area, message Box
      table */}

      
    </Container>
  )
}

export default Template