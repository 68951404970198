import './App.css';
import Home from './Pages/Home';
import Navbar from './Components/NavBar/NavBar';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';

import Footer from './Components/Footer/Footer';
import ScrollToTop from './Hooks/ScrollToTop';
import Aos from "aos";
import { Fragment, useEffect } from "react";
import "aos/dist/aos.css";
import RulesandRegulation from './Pages/RulesandRegulation';
import WardensandSubWardens from './Pages/WardensandSubWardens';
import UnderConstruction from './Pages/UnderConstruction';
import NotFound from './Pages/NotFound';
import LoginPage from './Pages/LoginPage';
import ContactUsPage from './Pages/ContactUsPage';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import Alert from './Components/alert/Alert';

import AdminHome from './Pages/admin/AdminHome';
import AdminAddUsers from './Pages/admin/AdminAddUsers';
import AdminViewUsers from './Pages/admin/AdminViewUsers';
import AdminAddHostels from './Pages/admin/AdminAddHostels';
import AdminUpdateHostels from './Pages/admin/AdminUpdateHostels';
import AdminViewHostels from './Pages/admin/AdminViewHostels';
import StudentHome from './Pages/student/StudentHome';
import StudentCreateQuery from './Pages/student/StudentCreateQuery';
import StudentViewQueries from './Pages/student/StudentViewQueries';
import StudentViewQuery from './Pages/student/StudentViewQuery';
import StudentUpdate from './Pages/student/StudentUpdate';
import SubWardenHome from './Pages/sub warden/SubWardenHome';
import SubWardenViewQuery from './Pages/sub warden/SubWardenViewQuery';
import SubWardenHandleQuery from './Pages/sub warden/SubWardenHandleQuery';
import SubWardenStudents from './Pages/sub warden/SubWardenStudents';
import SubWardenAddStudents from './Pages/sub warden/SubWardenAddStudents';
import SubWardenViewStudent from './Pages/sub warden/SubWardenViewStudent';
import AcademicWardenHome from './Pages/academic warden/AcademicWardenHome';
import AcademicWardenViewQuery from './Pages/academic warden/AcademicWardenViewQuery';
import AcademicWardenHandleQuery from './Pages/academic warden/AcademicWardenHandleQuery';
import AcademicWardenViewStudent from './Pages/academic warden/AcademicWardenViewStudent';
import AcademicWardenStudents from './Pages/academic warden/AcademicWardenStudents';
import ChiefEngineerHome from './Pages/chief engineer/ChiefEngineerHome';
import ChiefEngineerHandleQuery from './Pages/chief engineer/ChiefEngineerHandleQuery';
import ChiefEngineerViewQuery from './Pages/chief engineer/ChiefEngineerViewQuery';
import DVCHome from './Pages/dvc/DVCHome';
import DVCViewQuery from './Pages/dvc/DVCViewQuery';
import DVCHandleQuery from './Pages/dvc/DVCHandleQuery';
import VCHome from './Pages/vc/VCHome';
import VCViewQuery from './Pages/vc/VCViewQuery';
import VCHandleQuery from './Pages/vc/VCHandleQuery';

import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';
import Template from './Pages/template-samples/Template';

import Roles, { BackRoutes } from './Data/NavItemsData';
import ViewQueries from './Components/shared/ViewQueries/ViewQueries';
import SubWardenViewQueries from './Pages/sub warden/SubWardenViewQueries';
import AcademicWardenViewQueries from './Pages/academic warden/AcademicWardenViewQueries';
import ChiefEngineerViewQueries from './Pages/chief engineer/ChiefEngineerViewQueries';
import DVCViewQueries from './Pages/dvc/DVCViewQueries';
import VCViewQueries from './Pages/vc/VCViewQueries';
import UpdateMe from './Pages/UpdateMe';
import RequireAuth from './Components/Routing/RequireAuth';
import Loading from './Pages/Loading';
import SubWardenCommitteMember from './Pages/sub warden/SubWardenCommitteMember';

import content, { images, Mobileimages } from './Data/Content';

import img1 from './images/img1.jpg'
import { BackButton } from './Data/NavItemsData';
import SubWardenReactiveUsers from './Pages/sub warden/SubWardenReactiveUsers';
import AdminReactiveUsers from './Pages/admin/AdminReactiveUsers';
import ARHome from './Pages/asistant registrar/ARHome';
import ARViewQueries from './Pages/asistant registrar/ARViewQueries';
import ARViewQuery from './Pages/asistant registrar/ARViewQuery';
import SOHome from './Pages/security officer/SOHome';
import SOViewQueries from './Pages/security officer/SOViewQueries';
import SOViewQuery from './Pages/security officer/SOViewQuery';
import { useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";

function App() {
  const[view1,setView] = useState(window.innerWidth >= 800 ? true : false);
  useEffect(()=>{
    const resize = ()=>{
      if(window.innerWidth >= 800){
          setView(true);
      }else{
          setView(false);
      }
    }
    window.addEventListener('resize',resize);
  })

  useEffect(() => {
    Aos.init({duration:1000}); 
  }, [])
  return (
    <div>
      <AuthState>
          <AlertState>
            <Router>
              <Fragment>
                <Alert />
                <div className="App">
                  <Navbar />
                  <ScrollToTop />
                  <Routes>
             
    
                    {/* ======================== ADMIN routes [only system ADMIN can access] start ======================= */}
    
                    <Route element={<RequireAuth allowedRoles={['admin']} />} >
                   
                      <Route exact path='/admin' element={<AdminHome Navs={Roles[4]} view2Prop={view1}/>} /> {/* ok */}
                      <Route exact path='/admin-add-remove-users' element={<AdminAddUsers nav3={BackRoutes[0]}/>} />{/* ok */}
                      <Route exact path='/admin-view-users' element={<AdminViewUsers nav3={BackRoutes[0]}/>} />
                      <Route exact path='/admin-add-hostels' element={<AdminAddHostels nav3={BackRoutes[0]}/>} /> {/* ok */}
                      <Route exact path='/admin-hostels' element={<AdminViewHostels nav3={BackRoutes[0]}/>} /> {/* ok */}
                      <Route exact path='/admin-update-hostel/:id' element={<AdminUpdateHostels nav3={BackRoutes[0]}/>} /> {/* ok */}
                      <Route exact path='/admin-re-active-user' element={<AdminReactiveUsers nav3={BackRoutes[0]}/>} /> {/* ok */}
    
                    </Route>
                    {/* ======================== ADMIN routes end ======================= */}
    
                    {/* ======================== STUDENT routes [only STUDENT can access] start ======================= */}
                    
                    <Route element={<RequireAuth allowedRoles={['student']} />} >
    
                      <Route exact path='/student' element={<StudentHome Navs={Roles[6]} view2Prop={view1}/>} />{/* ok */}
                      <Route exact path='/student/create-query' element={<StudentCreateQuery nav3={BackRoutes[6]}/>} /> {/* ok */}
                      <Route exact path='/student/queries' element={<StudentViewQueries nav3={BackRoutes[6]}/>} />{/* ok */}
                      <Route exact path='/student/view-query/:id' element={<StudentViewQuery nav3={BackRoutes[6]}/>} />{/* ok */}
                      <Route exact path='/student/update-my-profile' element={<StudentUpdate nav3={BackRoutes[6]}/>} />{/* ok */}
                    </Route>
              
                    {/* ======================== STUDENT routes end ======================= */}
    
                    {/* ======================== SUB WARDEN routes [only  SUB WARDEN  can access] start ======================= */}
                    <Route element={<RequireAuth allowedRoles={['sub_warden']} />} >
                    
                      <Route exact path='/subwarden' element={<SubWardenHome Navs={Roles[1]} view2Prop={view1}/>} />{/* ok */}
                      <Route exact path='/subwarden/committe-members' element={<SubWardenCommitteMember nav3={BackRoutes[1]}/>} />{/* ok */}
                      <Route exact path='/subwarden/queries' element={<SubWardenViewQueries nav3={BackRoutes[1]}/>} /> {/* ok */}
                      <Route exact path='/sub_warden/view-query/:id' element={<SubWardenViewQuery nav3={BackRoutes[1]}/>} />{/* ok */}
                      <Route exact path='/sub_warden/handle-query/:id' element={<SubWardenHandleQuery nav3={BackRoutes[1]}/>} />{/* ok */}
                      <Route exact path='/subwarden/students' element={<SubWardenStudents nav3={BackRoutes[1]}/>} /> {/* ok */}
                      <Route exact path='/subwarden/view-student/:id' element={<SubWardenViewStudent nav3={BackRoutes[1]}/>} />
                      <Route exact path='/subwarden/add-students' element={<SubWardenAddStudents nav3={BackRoutes[1]}/>} />
                      <Route exact path='/subwarden/re-active-students' element={<SubWardenReactiveUsers nav3={BackRoutes[1]}/>} />
                    </Route>
                    
    
                    {/* ======================== SUB WARDEN  routes end ======================= */}
    
                    {/* ======================== ACADEMIC WARDEN routes [only  ACADEMIC WARDEN  can access] start ======================= */}
                    <Route element={<RequireAuth allowedRoles={['academic_warden']} />} >
                    
                      <Route exact path='/academicwarden' element={<AcademicWardenHome Navs={Roles[0]} view2Prop={view1}/>} />{/* ok */}
                      <Route exact path='/academicwarden/queries' element={<AcademicWardenViewQueries nav3={BackRoutes[2]}/>} />
                      <Route exact path='/academic_warden/view-query/:id' element={<AcademicWardenViewQuery nav3={BackRoutes[2]}/>} />{/* ok */}
                      <Route exact path='/academic_warden/handle-query/:id' element={<AcademicWardenHandleQuery nav3={BackRoutes[2]}/>} />{/* ok */}
                      <Route exact path='/academicwarden/students' element={<AcademicWardenStudents nav3={BackRoutes[2]}/>} />{/* ok */}
                      <Route exact path='/academicwarden/view-student/:id' element={<AcademicWardenViewStudent nav3={BackRoutes[2]}/>} />
                    
                    </Route>
    
    
                    {/* ======================== ACADEMIC WARDEN  routes end ======================= */}
    
              
                    {/* ======================== CHIEF ENGINEER routes [only  CHIEF ENGINEER  can access] start ======================= */}
                    <Route element={<RequireAuth allowedRoles={['cheif_engineer']} />} >
                    
                      <Route exact path='/chief-engineer' element={<ChiefEngineerHome Navs={Roles[5]} view2Prop={view1}/>} />{/* ok */}
                      <Route exact path='/chief-engineer/queries' element={<ChiefEngineerViewQueries nav3={BackRoutes[3]}/>} />
                      <Route exact path='/cheif_engineer/view-query/:id' element={<ChiefEngineerViewQuery nav3={BackRoutes[3]}/>} />{/* ok */}
                      <Route exact path='/cheif_engineer/handle-query/:id' element={<ChiefEngineerHandleQuery nav3={BackRoutes[3]}/>} />{/* ok */}
                    </Route>
                    
    
    
                    {/* ======================== CHIEF ENGINEER  routes end ======================= */}
              
    
                    {/* ======================== DVC routes [only  DVC  can access] start ======================= */}
                    <Route element={<RequireAuth allowedRoles={['dvc']} />} >
                    
                      <Route exact path='/dvc' element={<DVCHome Navs={Roles[2]} view2Prop={view1}/>} />{/* ok */}
                      <Route exact path='/dvc/queries' element={<DVCViewQueries nav3={BackRoutes[4]} />} />
                      <Route exact path='/dvc/view-query/:id' element={<DVCViewQuery nav3={BackRoutes[4]}/>} />{/* ok */}
                      <Route exact path='/dvc/handle-query/:id' element={<DVCHandleQuery nav3={BackRoutes[4]}/>} />{/* ok */}
                    </Route>
                    
    
    
                    {/* ======================== DVC  routes end ======================= */}
    
                    {/* ======================== Assitant Registrar routes [only  AR  can access] start ======================= */}
                    
                    <Route element={<RequireAuth allowedRoles={['assistant_registrar']} />} >
                    <Route exact path='/assistant_registrar' element={<ARHome Navs={Roles[7]} view2Prop={view1}/>} />{/* ok */}
                    <Route exact path='/assistant_registrar/queries' element={<ARViewQueries nav3={BackRoutes[7]} />} />
                    <Route exact path='/assistant_registrar/view-query/:id' element={<ARViewQuery nav3={BackRoutes[7]}/>} />{/* ok */}
                    </Route>
                    {/* ======================== Assitant Registrar  routes end ======================= */}
    
                    {/* ======================== Security Officer routes [only  Sec Officer  can access] start ======================= */}
                    <Route exact path='/security_officer' element={<SOHome Navs={Roles[8]} view2Prop={view1}/>} />{/* ok */}
                    <Route exact path='/security_officer/queries' element={<SOViewQueries nav3={BackRoutes[8]} />} />
                    <Route exact path='/security_officer/view-query/:id' element={<SOViewQuery nav3={BackRoutes[8]}/>} />{/* ok */}
    
                    {/* ======================== Security Officer  routes end ======================= */}
    
    
                    {/* ======================== VC routes [only  VC  can access] start ======================= */}
                    <Route element={<RequireAuth allowedRoles={['vc', 'admin']} />} >
                    
                      <Route exact path='/vc' element={<VCHome Navs={Roles[3]} view2Prop={view1}/>} />{/* ok */}
                      <Route exact path='/vc/queries' element={<VCViewQueries nav3={BackRoutes[5]}/>} />
                      <Route exact path='/vc/view-query/:id' element={<VCViewQuery nav3={BackRoutes[5]}/>} />{/* ok */}
                      <Route exact path='/vc/handle-query/:id' element={<VCHandleQuery nav3={BackRoutes[5]}/>} />{/* ok */}
                    </Route>
    
                    {/* ======================== VC  routes end ======================= */}
              
                    
                    {/* ================ UPDATE ME  route START [only  sub_w, AC_w, Chief_eng,DVC,VC  can access] ======================= */}
                    <Route element={
                      <RequireAuth allowedRoles={
                        ['sub_warden', 'admin', 'student', 'academic_warden', 'cheif_engineer', 'dvc', 'vc', 'assistant_registrar','security_officer']
                      } />} >
                    
                      <Route exact path='/update-me' element={<UpdateMe nav3={BackRoutes[4]}/>} />
                    </Route>
                    
                    {/* ======================== UPDATE ME route end ======================= */}
                    
              
    
                    {/* ======================== public routes [anyone can access] start ======================= */}
                    <Route exact path='/' element={<Home Covertext={content[0]} CoverImage={view1 ?  images[0] : Mobileimages[0]} view2Prop={view1}/>} />
                    {/* <Route exact path='/' element={<Home Covertext={content[0]} CoverImage={img1} />} /> */}
                    <Route exact path='/RulesandRegulation' element={<RulesandRegulation Covertext={content[1]} CoverImage={view1 ? images[1] : Mobileimages[1]} />} />
                    <Route exact path='/WardensandSubWardens' element={<WardensandSubWardens Covertext={content[2]} CoverImage={view1 ? images[2] : Mobileimages[2]} />} />
                    <Route exact path='/under-construction' element={<UnderConstruction />} />
                    <Route exact path='/contact-us' element={<ContactUsPage />} />
                    <Route exact path='/login' element={<LoginPage />} />
                    <Route exact path='/logout' element={<Home Covertext={content[0]} CoverImage={view1 ?  images[0] : Mobileimages[0]} />} />
                    <Route exact path='/forgot-password' element={< ForgotPassword />} />
                    <Route exact path='/reset-password/:token' element={<ResetPassword />} />
                    <Route exact path='/unauth' element={<Template />} />
    
                    <Route path='*' element={<NotFound />} />
                    {/* ======================== public routes end ======================= */}
    
             
                  </Routes>
                  <Footer />
                </div>
              </Fragment>
            </Router>
          </AlertState>
        </AuthState>

      
      
    </div>
    
  )
}

export default App;
