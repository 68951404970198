import React from 'react'
import { RiTodoLine,RiFileEditFill, RiEdit2Fill} from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  SideNavBar,
  Header,
  Menu,
  Role,
  RoleName,
  SideNavs,
  RoleContainer,
  NavIcon,
  NavText,
  MenuItem,
  MenuItems,
  MenuText,
  MenuIcon,
  HeaderContainer,
  P
} from './HomeElements'
import { Query } from '../handleQuery/HandleQueryElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import useAuth from '../../../Hooks/useAuth';
const UserHome = (props) => {

  const { logout } = useAuth();
  const navigate = useNavigate();

  const { user } = useAuth();
  
  const scrollToTop = ()=>{
    if(window.pageYOffset > 50){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  }
  const data1 = Array.from(props.Navs1.Navs);
 
  const onClick = (link) => {
    if (link === '/logout') {
      logout();

    }
  }

  return (
    <Container>
      {
        props.view2Prop1 ? 
         <>
            <SideNavBar>
        <RoleContainer>
          <Role>{props.Navs1.Role}</Role>
          <RoleName> {user?.title?.charAt(0).toUpperCase()+user?.title?.slice(1) || 'Mr/Ms'}. {user?.name || 'User'} </RoleName>
        </RoleContainer>
        <>
          {
            data1.map(navs=>{
              return(
                <SideNavs onClick={scrollToTop}>
                    <NavIcon>
                      {navs.icon}
                    </NavIcon>
                    <NavText>
                    <p><Link to={navs.link} onClick={()=>{onClick(navs.link)}}  className="lin">{navs.text}</Link></p>
                  </NavText>
                </SideNavs>
              )
            })
          }
        </>
      </SideNavBar>
      <Menu>
        <HeaderContainer>
          <Header>Welcome</Header>
        </HeaderContainer>
        <MenuItems>
          {
            data1.map(navs=>{
              return(
                <MenuItem onClick={scrollToTop}>
                  <Link to={navs.link} onClick={()=>{onClick(navs.link)}} className="lin"><P>{navs.text}</P></Link>
                </MenuItem>
              )
            })
          }

        </MenuItems>
      </Menu>
         </>
         :
        <Menu>
          <RoleContainer>
            <Role>{props.Navs1.Role}</Role>
            <RoleName> {user?.title?.toUpperCase() || 'Mr/Ms'}. {user?.name || 'User'} </RoleName>
          </RoleContainer>
        <HeaderContainer>
          <Header>Welcome</Header>
        </HeaderContainer>
        <MenuItems>
          {
            data1.map(navs=>{
              return(
                <MenuItem onClick={scrollToTop}>
                  <MenuIcon>
                      {navs.icon}
                  </MenuIcon>
                    <MenuText>
                      <Link to={navs.link} onClick={()=>{onClick(navs.link)}} className="lin"><P>{navs.text}</P></Link>
                    </MenuText>
                  </MenuItem>
              )
            })
          }

        </MenuItems>
      </Menu>

      }
      
    </Container>
  )
}

export default UserHome