import React from 'react'
import { Container,H1,SpinnerContainer ,LinkRow} from './ViewStudentElements';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import BackwordButton from '../../Button/BackButton/BackButton';



const ViewStudent = (props) => {
  //let rows = []

  const rows = [
    createData('Mudith', 'Perera', 'M M B', 'SC/2019/11153', 'Science','2','None','None','None','bla bla','None'),
    //createData('Piumantha', 'Cherin', 'P P P', 'SC/2019/111P', 'Science','2','None','None','None','bla bla','None')
];

function createData(firstName, lastName, initials, studentNo, faculty, level, hostel, roomNo, hostelFee, specialRemarks, committeeMember) {
    return { firstName, lastName, initials, studentNo, faculty, level, hostel, roomNo, hostelFee, specialRemarks, committeeMember };
  }

  //const student = props?.data?.data?.student

  // student.forEach(student => {
  //       const h = {
  //           id:student.id,
  //           firstName: student.firstName,
  //           lastName: student.lastName,
  //           initials: student.initials,
  //           studentNo: student.studentNo,
  //           faculty: student.faculty,
  //           level: student.level,
  //           hostel: student.hostel,
  //           roomNo: student.roomNo,
  //           hostelFee: student.hostelFee,
  //           specialRemarks: student.specialRemarks,
  //           committeeMember: student.committeeMember,
  //       }

  //       rows.push(h);
  //   })

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
                <StyledTableCell>First Name</StyledTableCell>
                <StyledTableCell align="right">Last Name</StyledTableCell>
                <StyledTableCell align="right">Initials</StyledTableCell>
                <StyledTableCell align="right">Student No</StyledTableCell>
                <StyledTableCell align="right">Faculty</StyledTableCell>
                <StyledTableCell align="right">Level</StyledTableCell>
                <StyledTableCell align="right">Hostel</StyledTableCell>
                <StyledTableCell align="right">Room No</StyledTableCell>
                <StyledTableCell align="right">Hostel Fee</StyledTableCell>
                <StyledTableCell align="right">Special Remarks</StyledTableCell>
                <StyledTableCell align="right">Committee Member</StyledTableCell>
            </TableRow>
          </TableHead>
            <TableBody>
            {rows?.map((row) => (
                <TableRow>
                    <StyledTableCell component="th" scope="row">{row.firstName}</StyledTableCell>
                    <StyledTableCell align="right">{row.lastName}</StyledTableCell>
                    <StyledTableCell align="right">{row.initials}</StyledTableCell>
                    <StyledTableCell align="right">{row.studentNo}</StyledTableCell>
                    <StyledTableCell align="right">{row.faculty}</StyledTableCell>
                    <StyledTableCell align="right">{row.level}</StyledTableCell>
                    <StyledTableCell align="right">{row.hostel}</StyledTableCell>
                    <StyledTableCell align="right">{row.roomNo}</StyledTableCell>
                    <StyledTableCell align="right">{row.hostelFee}</StyledTableCell>
                    <StyledTableCell align="right">{row.specialRemarks}</StyledTableCell>
                    <StyledTableCell align="right">{row.committeeMember}</StyledTableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
      </TableContainer>
      <BackwordButton nav1={props.nav2}/>
    </Container>
    
  )
}

export default ViewStudent