import styled from "styled-components";
export const Container = styled.div`
    width:60%;
    border :1px solid #339BFF;
    margin:13% auto ;
    padding: 0 2rem ;
    display:flex ;
    flex-direction:column ;
    justify-content: center;
    border-radius:30px ;
    @media screen and (max-width: 800px){
        margin-top: 30%;
        width: 75%;
    }
`
export const Top = styled.div`
    display:grid;
    
`
export const Form = styled.form`
    /* @media screen and (max-width: 800px){
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;   
    } */
`
export const Header = styled.h2`
    font-size: 30px;
    justify-content: center;
    text-transform: uppercase;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  @media screen and (max-width: 800px){
    font-size: 1rem;
    font-weight: 600;
  }
`
export const Buttons = styled.div`
    margin: 2% 0;
    width: 100%;
`
export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 800px) {
         flex-wrap: wrap;
    }
`
export const TextBox = styled.div`
    margin: 2%;
    width: 100%;
`
