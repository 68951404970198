import React from 'react'
import AdminViewHostelsTable from '../../Components/1_admin/view hostels/AdminViewHostelsTable';
import { Container, H1,SpinnerContainer } from '../../Components/1_admin/view hostels/AdminViewHostelsTableElements';
import useFetch from '../../Hooks/useFetch';
import ClipLoader from "react-spinners/ClipLoader";
const AdminViewHostels = (props) => {

  const { data, isPending, isError } = useFetch('/api/v1/hostels');

  // console.log(data?.results);
  // console.log(data?.data?.hostels);

  return (
    
    <>
      <Container>
        <H1>HOSTELS</H1>
        
        {isError && <h1>Something went wrong  ... 😥</h1>}

        {isPending && <SpinnerContainer><ClipLoader size={50} /></SpinnerContainer>}
        
        {data && <AdminViewHostelsTable data={data} nav2={props.nav3}/>}
      </Container>

      
      
    </>
  );
}

export default AdminViewHostels