
import { Button, TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { AiOutlineUserAdd, AiOutlineUsergroupAdd, AiOutlineUsergroupDelete ,AiOutlineUserDelete} from "react-icons/ai";
import useAlert from '../../../Hooks/useAlert';
import { ERROR, SUCCESS } from './../../alert/Alert_Types';
import { ClipLoader } from 'react-spinners';
import {
    Container,
    AddStudents,
    Header,
    Row,
    Row1,
    TextBox,
    AddGroup,
    Buttons,
    HR,
    RemoveGroup
} from "./addStudenEelemet"

import BackwordButton from "../../Button/BackButton/BackButton";
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
const AddStudent = (props) => {
  const [email, setEmail] = useState('');
  const [admissionYear, setAdmissionYear] = useState('');
  const [groupAdmissionYear, setGroupAdmissionYear] = useState('');
  const [removeGroupAdmissionYear, setRemoveGroupAdmissionYear] = useState('');
  const [pending, setPending] = useState({
    addOne: false,
    addGroup: false,
    remove: false,
    removeOne:false
  });
  const [faculty, setFaculty] = useState('');


  const { setAlert } = useAlert();
    
  const [emails, setEmails] = useState([]);
  const [removeEmail, setRemoveEmail] = useState('');

  // define faculties for the dropdown
  const Faculties = [
    {
      value: 'science',
      label: 'Science',
    },
    {
      value: 'agriculture',
      label: 'Agriculture',
    },
    {
      value: 'allied health science',
      label: 'Allied Health Science',
    },
    {
      value: 'engineering',
      label: 'Engineering',
    },
    {
      value: 'fisheries and marine science',
      label: 'Fisheries And Marine Science',
    },
    {
      value: 'humanities and social science',
      label: 'Humanities And Social Science',
    },
    {
      value: 'management and finance',
      label: 'Management And Finance',
    },
    {
      value: 'medicine',
      label: 'Medicine',
    },
    {
      value: 'technology',
      label: 'Technology',
    },
    {
      value: 'art',
      label: 'Art',
    }
  ];

    
  //single student add
  const onSubmit = async e => {
    e.preventDefault();
    const formData = { email, admissionYear };
    setPending({ addOne: true, addGroup: false, remove: false,removeOne:false });
    try {
      const res = await axios.post('/api/v1/students', formData);
      // console.log({ res });
      if (res.status === 201) {
        setPending({ addOne: false, addGroup: false, remove: false,removeOne:false });
        setAlert(`${res.data.data.message || 'Success'} 👍`, SUCCESS, 5000)
      }
    } catch (error) {
      // console.log(error.ressonse?.data);
      // console.log(error.response.data.message);
      setAlert(`${error?.response.data.message} 😥`, ERROR, 5000);
      setPending({ addOne: false, addGroup: false, remove: false ,removeOne:false});
    }
  }

  //Group add
  const onSubmitGroup = async e => {
    e.preventDefault();

    if (!groupAdmissionYear.match(/[0-9][0-9][0-9][0-9]\/[0-9][0-9][0-9][0-9]/)) {
      setAlert(`Invalid admission year !`, ERROR, 5000);
      setGroupAdmissionYear('');
    }

    const formData = { admissionYear:groupAdmissionYear, emails }
    // console.log({ formData });
    setPending({ addOne: false, addGroup: true, remove: false,removeOne:false });
    try {
      const res = await axios.post('/api/v1/students/multiple/',formData);
      if(res.status === 201){
        // console.log({res});
          setPending({addOne:false,addGroup:false,remove:false,removeOne:false});
          setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
      }
    } catch (error) {
      // console.log(error.ressonse?.data);
      // console.log(error.response.data.message);
      setAlert(`${error?.response.data.message} 😥`, ERROR, 10000);
      setPending({addOne:false,addGroup:false,remove:false,removeOne:false});
    }
  }

  //Remove Group
  const onRemove = async e => {
    e.preventDefault();
    const admissionYear = removeGroupAdmissionYear;
    setPending({ addOne: false, addGroup: false, remove: true,removeOne:false });
    try {
      const res = await axios.delete(`/api/v1/students/removeBatch?admissionYear=${admissionYear}&faculty=${faculty}`);
      if (res.status === 204) {
        setPending({ addOne: false, addGroup: false, remove: false ,removeOne:false});
        setAlert(`${res.data.message || 'Success'} 👍`, SUCCESS, 5000);
      }
    } catch (error) {
      // console.log(error.ressonse?.data);
      // console.log(error.response.data.message);
      setAlert(`${error?.response.data.message} 😥`, ERROR, 5000);
      setPending({ addOne: false, addGroup: false, remove: false,removeOne:false });
    }
  }

  // Remove single student
  const onSingleRemove = async e => {
    e.preventDefault();

    setPending({ addOne: false, addGroup: false, remove: false, removeOne: true });

    try {
      const res = await axios.delete(`/api/v1/students/${removeEmail}`);
      // console.log({res});
      if (res.status === 204) {
        setAlert(`${res.data.message || 'Success !'} 👍`, SUCCESS, 5000);
      }
      

    } catch (error) {
      // console.log(error.response?.data);
      // console.log(error.response?.data?.message);
      setAlert(`${error?.response?.data?.message || 'Error'} 😥`, ERROR, 5000);
    }
    setPending({ addOne: false, addGroup: false, remove: false, removeOne: false });
    setRemoveEmail('');
  }


  return (
    <Container>
      <AddStudents>
        <Header>
          <h2>Add a New Student</h2>
        </Header>
        <form onSubmit={onSubmit}>
          <Row>
            <TextBox>
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </TextBox>
            <TextBox>
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Admission Year"
                value={admissionYear}
                onChange={e => setAdmissionYear(e.target.value)}
                placeholder="2019/2020"
              />
            </TextBox>
          </Row>
          <Row>
            <Buttons>
              {
                pending.addOne ?
                  <Button type="submit" variant="contained" color='success' disabled size='small' endIcon={!pending.addOne && <AiOutlineUserAdd />} >
                    <ClipLoader size={20} color={'#1ba3de'} />
                  </Button>
                  : <Button type="submit" variant="contained" color='success' size='small' endIcon={!pending.addOne && <AiOutlineUserAdd />} >
                    Add Student
                  </Button>
              }
            </Buttons>
          </Row>
        </form>
      </AddStudents>
      <HR></HR>
      <AddGroup>
        <Header>
          <h2>Add a New Group of Students</h2>
        </Header>
        <form method="post" onSubmit={onSubmitGroup}>
          <Row>
            <TextBox>
              <TextField
                id="outlined-multiline-static"
                label="Enter Student Emails"
                multiline
                required
                rows={10}
                fullWidth
                onChange={e => {
                  setEmails(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="email_1@gmail.com
email_2@gmail.com
email_3@gmail.com
email_4@gmail.com"
              />
            </TextBox>
            <TextBox>
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Admission Year"
                value={groupAdmissionYear}
                onChange={e => setGroupAdmissionYear(e.target.value)}
                placeholder="2019/2020"
              />
            </TextBox>
          </Row>
          <Typography variant="overline" display="block" gutterBottom>
       <h2>🛑Make sure <u>emails</u> and <u>admission</u>  year are correct ! Cannot undo after submit !🛑</h2> 
      </Typography>
          <Row>
            <Buttons>
              {
                pending.addGroup ?
                  <Button type="submit" variant="contained" color='success' disabled size='small' endIcon={!pending.addGroup && <AiOutlineUsergroupAdd />} >
                    <ClipLoader size={20} color={'#1ba3de'} />
                  </Button>
                  : <Button type="submit" variant="contained" color='success' size='small' endIcon={!pending.addGroup && <AiOutlineUsergroupAdd />} >
                    Add Group
                  </Button>
              }
            </Buttons>
          </Row>
        </form>
      </AddGroup>
      <HR></HR>
      <RemoveGroup>
        <Header>
          <h2>Remove Group Of Students</h2>
        </Header>
        <form onSubmit={onRemove}>
          <Row1>
            <TextBox>
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Admission Year"
                value={removeGroupAdmissionYear}
                onChange={e => setRemoveGroupAdmissionYear(e.target.value)}
                placeholder="2019/2020"
              />
            </TextBox>
            <TextField
            id="faculty"
            select
            label="faculty"
            value={faculty}
            onChange={e=>{setFaculty(e.target.value)}}
            style = {{width: 350, float: "left"}}
            
            // helperText="Please select your currency"
          >
            {Faculties.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          </Row1>
          <Row1>
            <Buttons>
              {
                pending.remove ?
                  <Button type="submit" variant="contained" disabled color='error' size='small' endIcon={!pending.remove && <AiOutlineUsergroupDelete />} >
                    <ClipLoader size={20} color={'#1ba3de'} />
                  </Button>
                  : <Button type="submit" variant="contained" color='error' size='small' endIcon={!pending.remove && <AiOutlineUsergroupDelete />} >
                    Remove Group
                  </Button>
              }
            </Buttons>
          </Row1>
        </form>
      </RemoveGroup>
      <HR></HR>
      <AddStudents>
        <Header>
          <h2>Remove a single Student</h2>
        </Header>
        <form onSubmit={onSingleRemove}>
          <Row1>
            <TextBox>
              <TextField
                fullWidth
                required
                id="outlined-required"
                label="Email"
                value={removeEmail}
                onChange={e => setRemoveEmail(e.target.value)}
              />
            </TextBox>
          </Row1>
          <Row1>
            <Buttons>
              {
                pending.removeOne ?
                  <Button type="submit" variant="contained" color='error' disabled size='small' endIcon={!pending.removeOne && <AiOutlineUserDelete />} >
                    <ClipLoader size={20} color={'#1ba3de'} />
                  </Button>
                  : <Button type="submit" variant="contained" color='error' size='small' endIcon={!pending.removeOne && <AiOutlineUserDelete />} >
                    Remove Student
                  </Button>
              }
            </Buttons>
          </Row1>
        </form>
      </AddStudents>

      <BackwordButton nav1={props.nav2} />
    </Container>
  );
};
 
export default AddStudent;