import React from 'react'
import ResetPasswordForm from '../Components/ResetPassowrdForm/ResetPasswordForm'

const ResetPassword = () => {
  return (
      <>
      <ResetPasswordForm/>
        
    </>
  )
}

export default ResetPassword