import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { RiEdit2Fill } from 'react-icons/ri';
import Button from '@mui/material/Button';
import useAuth from "../../../Hooks/useAuth.js";
import MenuItem from '@mui/material/MenuItem';
import { ERROR, SUCCESS } from '../../alert/Alert_Types';
import useAlert from '../../../Hooks/useAlert';
import axios from 'axios';
import BackwordButton from '../../Button/BackButton/BackButton';
import {
  Container,
  PersonalData,
  Header,
  Row,
  TextBox,
  PasswordReset,
  HR,
  Buttons,
  BackButton
}from './UpdateMeElements'
import useFetch from '../../../Hooks/useFetch';
import { ClipLoader } from 'react-spinners';

const titles = [
  {
    value: 'prof',
    Label: 'Prof'
  },
  {
    value: 'dr',
    Label: 'Dr'
  },
  {
    value: 'mr',
    Label: 'Mr'
  },
  {
    value: 'mrs',
    Label: 'Mrs'
  },
  {
    value: 'miss',
    Label: 'Miss'
  }
];

const UpdateMe = (props) => {
  const { user, logout } = useAuth();
  const [name, setName] = useState(user.name);
  const [title, setTitle] = useState(user.title);
  const [email, setEmail] = useState(user.email);
  const [password, setNewPassword] = useState('');
  const [currentPassword, setOldPwd] = useState('');
  const [passwordConfirm, setCheckOldPwd] = useState('');

  const [pending, setPending] = useState(false);
  const { setAlert } = useAlert();

  

  const onUpdate = async e => {
    e.preventDefault();
    const formData = { name, title };
    try {
      setPending(true);
      // console.log(formData);
      const res = await axios.patch('/api/v1/admin-users/updateMe', formData);
      // console.log(formData);
      // console.log({ res });
      
      if (res.status === 201) {
        // console.log(res);
        setAlert(`${res.data.status} 👍`, SUCCESS, 5000);
        logout();
      }
      setPending(false);

    } catch (error) {
      // console.log(error);
      // console.log(error.response.data.status);
      setAlert(`${error?.response.data.status} 🙄🤨`, ERROR, 5000);
      setPending(false);
    }
  }

  const onPasswordUpdate = async e => {
    e.preventDefault();
    const PasswordForm = { currentPassword, password, passwordConfirm };
    setPending(true);
    try {
      // console.log(PasswordForm);
      const res = await axios.patch('/api/v1/auth/updateMyPassword', PasswordForm);
      // console.log(PasswordForm);
      // console.log({ res });
      
      if (res.status === 201) {
        // console.log(res);
        setAlert(`${res.data.staus} 👍`, SUCCESS, 5000);
        logout();
      }
      setPending(false);
    } catch (error) {
      // console.log(error);
      // console.log(error.response.data.message);
      setAlert(`${error?.response.data.message} 🙄🤨`, ERROR, 5000);
      setPending(false);
    }
  }

  return (
    <Container>
      {user.role !== 'student' &&
        <PersonalData>
          <Header>
            <h2>Update Your Personl Data</h2>
          </Header>
          <form onSubmit={onUpdate}>
            <Row>
              <TextBox>
                <TextField
                  fullWidth
                  required
                  id="outlined-required"
                  label="Full Name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </TextBox>
              <TextBox>
                <TextField
                  id="title"
                  select
                  label="Title"
                  required
                  value={title}
                  onChange={e => { setTitle(e.target.value) }}
                  style={{ width: '100%' }}
                >
                  {titles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.Label}
                    </MenuItem>
                  ))}
                </TextField>
              </TextBox>
            </Row>
            <Row>
              <TextBox>
                <TextField
                  fullWidth
                  required
                  id="outlined-required"
                  label="Email"
                  value={email}
                  disabled={true}
                  onChange={e => setEmail(e.target.value)}
                />
              </TextBox>
            </Row>
            <Row>
              <Buttons>
                {pending
                  ? <Button type="submit" variant="contained" color='success' size='small' >
                    <ClipLoader color='#FFF' size={20} />
                  </Button>
                  
                  
                  :
                  <Button type="submit" variant="contained" color='success' size='small' endIcon={<RiEdit2Fill />}>
                    Update
                  </Button>
                  
                }
              </Buttons>
            </Row>
          </form>
        </PersonalData>

      }
      <HR></HR>
      <PasswordReset>
        <Header>
          <h2>Reset Your Password</h2>
        </Header>
        <form onSubmit={onPasswordUpdate}>
          <Row>
            <TextBox>
              <TextField
                fullWidth
                required
                type="password"
                autoComplete="current-password"
                
                id="outlined-password-input"
                label="Current Password"
                value={currentPassword}
                onChange={e => setOldPwd(e.target.value)}
              />
            </TextBox>
            <TextBox>
              <TextField
                fullWidth
                required
                type="password"
                autoComplete="current-password"
                id="outlined-password-input"
                label="New Password"
                value={password}
                onChange={e => setNewPassword(e.target.value)}
              />
            </TextBox>
          </Row>
          <Row>
            <TextBox>
              <TextField
                fullWidth
                required
                type="password"
                autoComplete="current-password"
                id="outlined-password-input"
                label="Confirm Password"
                value={passwordConfirm}
                onChange={e => setCheckOldPwd(e.target.value)}
              />
            </TextBox>
          </Row>
          <Row>
            <Buttons>
              {pending ?
                <Button type="submit" variant="contained" color='success' size='small' >
                  <ClipLoader color='#FFF' size={20} />
                </Button>
                :
                <Button type="submit" variant="contained" color='success' size='small' endIcon={<RiEdit2Fill />}>
                  Update
                </Button>
                  
              }
            </Buttons>
          </Row>
        </form>
      </PasswordReset>
    </Container>
  )
}

export default UpdateMe