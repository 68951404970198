import React from 'react'
import ForgotPasswordForm from '../Components/ForgotPasswordForm/ForgotPasswordForm'

const ForgotPassword = () => {
  return (
      <>
          <ForgotPasswordForm/>
    </>
  )
}

export default ForgotPassword