import styled from "styled-components";
import { Link } from "react-router-dom"
import { style } from "@mui/system";
export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    flex-wrap: wrap;
    margin: 5% auto ;
    background-color: #ECEFF1;
    height: 100%;
    overflow-y: auto;
    padding: 0;
    @media screen and (max-width:800px){
        margin: 30% 5% 5% 5%;
        width: 90%;
    }
` 
export const SideNavBar = styled.div`
    height: 100%;
    background-color: #49483E;
    color: #fff;
    width: 25%;
    margin: 0;
    padding: 4% 0;
    width:30% ;
`
export const Header = styled.h2`
        font-size: 4rem;
        text-transform: uppercase;
        font-family: "Lato", sans-serif;
        font-weight: 600;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        letter-spacing: 1.5rem;
        margin:0 0 8rem ;
        @media screen and (max-width:800px){
            font-size: 1.2rem;
            letter-spacing: 1rem;
            margin: 0 5% 30% 9%;
        }
`
export const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
`
export const Menu =styled.div`
    width: 70%;
    justify-content: center;
    height: 100%;
    align-items: center;  
    @media screen and (max-width:800px){
        width: 100%;
    }
`
export const RoleContainer = styled.div`
    margin: 15% 0 15% 0;
    @media screen and (max-width: 800px){
        margin: 0 0 5% 0;
        background-color: #49483E;
        color: #fff;
        padding: 2% 0;
    }
`
export const Role = styled.h3`
    font-size: 1.5rem;
    line-height: 3.5rem;
    letter-spacing: 0.35rem;
    margin: 3% 0 0 0;
    line-height:3rem ;
    @media screen and (max-width: 800px) {
           font-size: 1.2rem;
           margin: 0;
           line-height: 1.5rem;
    }
`
export const RoleName = styled.p`
    font-size: 0.9rem;
    font-weight: 500;
    margin: 1%;
    letter-spacing: 0.25rem;
    @media screen and (max-width: 800px) {
           font-size: 0.8rem;
           line-height: 1.5rem;
    }
`
export const SideNavs = styled.div`
    &{  
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: wrap;
        ${'' /* margin: 5% 10%; */}
        padding:1rem 2rem ;
        cursor: pointer;
        transition: color 0.3s;
        position: relative;
        z-index:1;
    }
    &:hover{
        color: #fff;
    }
`
export const SpanOverlay = styled.span`
        width: 0;
        height: 100%;
        position: absolute;
        background: #339BFF;
        opacity: 1;
        top: 0;
        left: -10%;
        right: 0;
        z-index: -1;
        transition: color 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
`
export const NavIcon = styled.div`
    flex-basis: 5%;
    margin-right: 5%;
`
export const NavText = styled.div`
    font-size: 18px;
    letter-spacing: 0.15rem;
`
export const MenuItem = styled.div`
    &{
        width: 40%;
        background-color: #49483E;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1% 0;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }
    &:hover{
        background-color: #339BFF;
        transform: scale(0.98);
    }
    @media screen and (max-width: 800px){
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 90%;
    }
`
export const MenuIcon = styled.div`
    flex-basis: 25%;
    @media screen {
     font-size : 12px;
    }
`
export const MenuText = styled.div`
    flex-basis: 75%;
` 
export const P = styled.p`
    width:fit-content;
    @media screen and (max-width: 800px){
        font-size: 12px;
    }
` 
export const MenuItems= styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -60px;
    color: #fff;
    letter-spacing: 0.2rem;
    font-size: 18px;
    @media screen and (max-width: 800px) {
        padding: 2%;
    }
    
  
`

