import styled from "styled-components";
import { Link } from "react-router-dom"
export const Container = styled.div`
    width:60%;
    border :1px solid #339BFF;
    margin:13% auto ;
    padding: 0 1rem ;
    display:flex ;
    flex-direction:column ;
    border-radius:30px ;
`
export const Top = styled.div`
    display:grid;
    
`
export const Header = styled.h2`
    font-size: 30px;
    justify-content: center;
    text-transform: uppercase;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`
export const Status = styled.div`
    font-size: 20px;
    text-align: right;
    ${'' /* color: blue; */}
    font-weight: 700;
    text-transform:uppercase ;
`
export const Query = styled.form`
    margin: 0 5%;
`
export const Text = styled.p`
    font-size: 18px;
    text-align: left;
    color: #49483E;
    font-weight: 500;
`
export const Buttons = styled.div`
    margin-top: 5%;
`
export const HR = styled.hr`
    height: 2px;
    background-color: #49483E;
    border: none;
    margin: 5% 0;
`;
export const BackButton = styled.div`
    margin: 5%;
    display: flex;
    justify-content: left;
    width: 80%;
`
