import React from 'react'
import { useParams } from 'react-router-dom'
import { H1,Container,SpinnerContainer } from '../../Components/1_admin/update hostel form/AdminUpdateHostelElements';
import AdminUpdateHostelForm from '../../Components/1_admin/update hostel form/AdminUpdateHostelForm';
import useFetch from '../../Hooks/useFetch';
import ClipLoader from "react-spinners/ClipLoader";

const AdminUpdateHostels = (props) => {

  const { id } = useParams();
  // fetch current hostel
  const { data, isPending, isError } = useFetch(`/api/v1/hostels/${id}`);

  const hostel = data?.data.hostel;
  return (
    <Container>
      <H1>Update HosteL</H1>
      {isError && <h1>Something went wrong  ... 😥</h1>}
      {isPending && <SpinnerContainer><ClipLoader  size={50} /></SpinnerContainer>}
      {data && <AdminUpdateHostelForm hostel={hostel} nav2={props.nav3}/>}
    </Container>
  );
}

export default AdminUpdateHostels