import React, { useEffect, useState } from 'react'
import { Container,FormGroup,H1,Form,SubmitBtnRow } from './AdminAddHostelFromElements';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import useFetch from '../../../Hooks/useFetch';
import { ClipLoader } from 'react-spinners';

import Button from '@mui/material/Button';
import { AiOutlineCloseCircle,AiOutlineSend } from "react-icons/ai";
import axios from 'axios';
import useAlert from '../../../Hooks/useAlert';
import { ERROR, SUCCESS } from './../../alert/Alert_Types';
import BackwordButton from '../../Button/BackButton/BackButton';

const AdminAddHostelForm = (props) => {

    
    const { setAlert } = useAlert();

          // fetch academic wardens from API
          const { data:academicWardens, isPending:awPending, isError:awError } = useFetch('/api/v1/admin-users?role=academic_warden');
    

          // fetch un assigned sub wardens from API
        //   const {data:subWardens, isPending:swPending, isError:swError } = useFetch('/api/v1/admin-users/available-subwardens');
      
    const [subWardens, setSubWardens] = useState([]);
    const [swPending, setSwPending] = useState(true);
    const [swError, setSwError] = useState(false);

    
    const [name, setName] = useState('');
    const [type, setType] = useState('boys');
    const [capacity, setCapacity] = useState('');
    const [premise, setPremise] = useState('');
    const [subWarden, setSubWarden] = useState('');
    const [academicWarden, setAcademicWarden] = useState('');

    const [pending, setPending] = useState(false);


    useEffect(() => {
  
        const fetchSubWardens = async () => {
            try {
                const res = await axios.get('/api/v1/admin-users/available-subwardens');
                // console.log({'sub wardens fetched':res.data.data.avalableSubWardens});
                setSubWardens(res.data.data.avalableSubWardens);
                setSwPending(false)
            } catch (error) {
                // console.log(error.response);
                setSwError(true);
                setSwPending(false)
            }
        }

        fetchSubWardens();

        // console.log();
    }, [subWarden])

    const onSubmit = async e => {
        e.preventDefault();

        const formData = { name, type, capacity, premises:premise, subWarden, academicWarden }
        
        // console.log(formData);
        setPending(true);
        try {
            
            const res = await axios.post('/api/v1/hostels', formData);
            // console.log({ res });
            if (res.status===201) {
                setPending(false);
                setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
                setSubWarden('')
            }

        } catch (error) {
            // console.log(error);
            // console.log(error.response?.data);
            // console.log(error.response?.data.message);
            setAlert(`${error?.response.data.message} 😥`, ERROR, 5000);
            setPending(false);
            setSubWarden('')
        }
    }

    const reset = e => {
        setName('');
        setType('boys');
        setCapacity('');
        setPremise('');
        setSubWarden('');
        setAcademicWarden('')
    }

    return (
        <Container>
            <H1>add hostel  </H1>
            <Form onSubmit={onSubmit}>
                
                <FormGroup>
                    <TextField
                        type='text'
                        id="outlined-basic"
                        label="Hostel Name"
                        variant="outlined"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                        fullWidth
                    />
                </FormGroup>
                <FormGroup>
                    <FormControl sx={{ m: 1, minWidth: 370 }} >
                        <InputLabel id="demo-simple-select-label">Hostel Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Hostel Type"
                            required
                            onChange={e => setType(e.target.value)}
                        >
                            <MenuItem value={'boys'}>Boys</MenuItem>
                            <MenuItem value={'girls'}>Girls</MenuItem>
                            <MenuItem value={'bikkhu'}>Bhikku</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <TextField
                        type='text'
                        id="outlined-basic"
                        label="Capacity"
                        variant="outlined"
                        value={capacity}
                        onChange={e => setCapacity(e.target.value)}
                        required
                        fullWidth
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        type='text'
                        id="outlined-basic"
                        label="Premise"
                        variant="outlined"
                        value={premise}
                        onChange={e => setPremise(e.target.value)}
                        required
                        fullWidth
                    />
                </FormGroup>

                {/*================ academic & sub wardens select ===================  */}

                <FormGroup>
                    <FormControl sx={{ m: 1, minWidth: 370 }} >
                        <InputLabel id="demo-simple-select-label">Academic Warden</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={academicWarden}
                            label="Select Academic Warden"
                            required
                            onChange={e => setAcademicWarden(e.target.value)}
                        >
                            {awPending && <p>still loading data ....</p>}
                            {awError && <p>error while fetching data ...</p>}
                            {academicWardens && academicWardens.data.users?.map((user, id) => {
                                return (
                                    <MenuItem key={id} value={user.id}>{user.name}</MenuItem>
                                    
                                )
                            })}
                            {academicWardens && academicWardens?.data.results === 0 ? <p>None Available</p> : <span></span>}

                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormControl sx={{ m: 1, minWidth: 370 }} >
                        <InputLabel id="demo-simple-select-label">Sub Warden</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subWarden}
                            label="Select Academic Warden"
                            required
                            onChange={e => setSubWarden(e.target.value)}
                        >
                            {swPending && <p>still loading data ....</p>}
                            {swError && <p>Error while fetching data ...</p>}
                            {subWardens && subWardens.map((user, id) => {
                                return (
                                    <MenuItem key={id} value={user.id}>{user.name}</MenuItem>
                                    
                                )
                            })}
                            {subWardens && subWardens?.length === 0 ? <p>None Available 😥</p> : <span></span>}
                        </Select>
                    </FormControl>
                </FormGroup>
                
                <SubmitBtnRow>
                    
                    <Button onClick={reset} color='error' variant="contained" endIcon={<AiOutlineCloseCircle />}>
                        Clear
                    </Button>
                    {pending
                        ? <Button type='submit' variant="contained" disabled endIcon={!pending && <AiOutlineSend />} >
                            <ClipLoader size={20} color={'#1ba3de'} />
                        </Button>
                        : <Button type='submit' variant="contained" endIcon={!pending && <AiOutlineSend />} >
                            Add Hostel
                        </Button>
                        
                    }
                </SubmitBtnRow>

            </Form>
            <BackwordButton nav1={props.nav2}/>
        </Container>
    );
}

export default AdminAddHostelForm