
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import {
  Container,
  Top,
  Header,
  Status,
  Query,
  Text,
  TextBox,
  BackButton
} from "./ViewQueryElements"
import { AiOutlineCheckCircle,AiOutlineCloseCircle,AiOutlineTool } from "react-icons/ai";
import IconButton from '@mui/material/IconButton';
import { MdPendingActions } from "react-icons/md";
import Tooltip from '@mui/material/Tooltip';
import BackwordButton from '../../Button/BackButton/BackButton';

const ViewQuery = (props) => {

  const [message, setMessage] = useState('');
// console.log({props});
  

  useEffect(() => {
    
    const history = props.data?.queryForwardHistory;
    let message = '';

    if (history.length != 0) {
      history.forEach((h, i) => {
        // console.log({ h });
        const msg = `
        \n\n[ #${i + 1} ]\nRole : ${h.forwardedBy?.role}.\nName : ${h.forwardedBy?.title.toUpperCase()}. ${h.forwardedBy?.name}\nEmail: ${h.forwardedBy?.email}\nComment : ${h?.forwardMessage}`
        // console.log({ msg });
        message = message.concat(msg);
      })
    }
    setMessage(message);

  }, [])

  return (
    
    <Query>
      <Status>
        {props.data.status}
        {props.data.status === 'solved' &&
          <Tooltip title="Query Solved">
          <IconButton aria-label="delete"  >
            <AiOutlineCheckCircle color='#056ef7' />
          </IconButton>
        </Tooltip>
          
        }
        {props.data.status === 'rejected' &&
          <Tooltip title="Query Rejected">
          <IconButton aria-label="delete"  >
            <AiOutlineCloseCircle color='#f71d05' />
          </IconButton>
        </Tooltip>
          
        }
        {props.data.status === 'pending' &&
          <Tooltip title="Query Pending ...">
          <IconButton aria-label="delete"  >
            <MdPendingActions color='#f7b705' />
          </IconButton>
        </Tooltip>
          
        }
        {props.data.queryType === 'maintenance' &&
          <Tooltip title="Maintenance Query">
          <IconButton aria-label="delete"  >
            <AiOutlineTool color='#f7b705' />
          </IconButton>
        </Tooltip>
          
        }
      </Status>
      <Text>Query Message</Text>
      <br />
      <div>
        <TextField
          id="outlined-multiline-static"
          label="Query Message"
          multiline
          rows={15}
          fullWidth
          // disabled
          // defaultValue={props.data.message}
          value={props.data.message+`\nCreated Date: ${props.data?.createdAt.split("T")[0]}`}
        />
        <br />
      </div>
      {props.data.status === 'pending' &&
        <div>
          <Text>Curerntly Forworded To</Text> <br />
          <TextBox>
            <TextField fullWidth id="outlined-basic"
              disabled
              value={`${props.data?.currentlyForwardedToUser?.title.toUpperCase()} .${props.data.currentlyForwardedToUser.name} - [${props.data.currentlyForwardedToUser?.role}]`}
              variant="outlined"
              
            />  <br />
          </TextBox>
        </div>
      }
      {props.data.status === 'solved' &&
        <>
          <div>
            <Text>Solved By</Text> <br />
            <TextBox>
              <TextField fullWidth id="outlined-basic" disabled
                value={`${props.data?.solvedBy?.title.toUpperCase()}. ${props.data?.solvedBy?.name} - [ ${props.data?.solvedBy?.role} ] - [ ${props.data?.solvedBy?.email} ] `} variant="outlined" />  <br />
            </TextBox>
          </div>
          <div>
            <Text>Solve Comment</Text>
            <br />
            <TextField
              id="outlined-multiline-static"
              label="Solve Comment"
              multiline
              rows={4}
              fullWidth
              // disabled
              // defaultValue={props.data.message}
              value={props.data.solvedComment}
            />
          </div>
        </>
      }
      {props.data.status === 'rejected' &&
        <>
          <div>
            <Text>Rejected By</Text> <br />
            <TextBox>
              <TextField fullWidth id="outlined-basic" disabled
                // value={props.data.rejectedBy.name} variant="outlined" 
                value={`${props.data.rejectedBy.title.toUpperCase()}. ${props.data?.rejectedBy?.name} - [ ${props.data?.rejectedBy?.role} ] - [ ${props.data?.rejectedBy?.email} ]`} variant="outlined" />  <br />
              
              <br />
            </TextBox>
          </div>
          <div>
            <Text>Reject Comment</Text>
            <br />
            <TextField
              id="outlined-multiline-static"
              label="Reject Comment"
              multiline
              rows={4}
              fullWidth
              // disabled
              // defaultValue={props.data.message}
              value={props.data.rejectedComment}
            />
          </div>
        </>
      }

      {props.data.queryForwardHistory.length != 0 &&
        <div>
          <Text>Query Forwarded History</Text>
          <br />
          <TextField
            id="outlined-multiline-static"
            label=""
            multiline
            rows={15}
            fullWidth
            // disabled
            // defaultValue={props.data.message}
            // value={props.data.message}
            value={message}
          />
        </div>
      }
        
      
      <BackwordButton nav1={props.nav2}/>
    </Query>
    
  )
}

export default ViewQuery