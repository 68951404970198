import React, { useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import ViewQueries from '../../Components/shared/ViewQueries/ViewQueries'
import {Container,Top,Header,SpinnerContainer,DropDownContainer} from '../../Components/shared/ViewQueries/ViewQueriesElements'
import useFetch from '../../Hooks/useFetch'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
const StudentViewQueries = (props) => {

  const [url, setUrl] = useState('/api/v1/queries/myQueries');

  const { data: queries, isPending: qPending, isError: qError } = useFetch(url);

  return (
    <Container>
      <Top><Header>Queries</Header></Top>
      {qPending && <SpinnerContainer><ClipLoader size={50} /></SpinnerContainer>}
      {qError && <h3>Something went wrong .... 😥  </h3>}
      {queries && <ViewQueries queries={queries} nav2={props.nav3}/>}
            
    </Container>
  )
};

export default StudentViewQueries