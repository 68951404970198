import React from 'react'
import UserHome from '../../Components/shared/Home/UserHome'

const ARHome = (props) => {
    return (
        <>
            <UserHome Navs1={props.Navs} view2Prop1 = {props.view2Prop}/>
        </>
    )
}

export default ARHome