import React, { useState } from 'react'
import { Form ,FormGroup,SubmitBtnRow} from './ReactiveUserFormElements'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { AiOutlineSend } from "react-icons/ai";
import { ClipLoader } from 'react-spinners';
import useAlert from '../../../Hooks/useAlert';
import axios from 'axios';
import { SUCCESS, ERROR } from './../../alert/Alert_Types';

const ReactiveUserForm = (props) => {

    const [email, setEmail] = useState();
    const [pending, setPending] = useState(false);
    
    const [emails, setEmails] = useState([]);
    const [pendingMultiple, setPendingMultiple] = useState(false);


    const { setAlert } = useAlert();

    const onSubmit = async(e) => {
        e.preventDefault();

        setPending(true);
        const formData = { email };

        try {
            const res = await axios.patch(props.url, formData);
            // console.log(res);
            if (res.status === 201) {
                setEmail('');
                setAlert(`${res.data?.message} 👍`, SUCCESS, 5000);
            }
        } catch (error) {
            // console.log(error);
            // console.log(error?.response?.data.message);
            setAlert(`${error?.response?.data.message} 😥`, ERROR, 5000);
        }

        setPending(false);


    }

    const onSubmitMultiple = async (e) => {
        e.preventDefault()

        setPendingMultiple(true);
        const formData = {emailList:emails};

        try {
            const res = await axios.patch('/api/v1/students/reActiveStudentList', formData);
            if (res.status === 200) {
                setEmails('');
                setAlert(`${res.data?.message} 👍`, SUCCESS, 5000);
            }
        } catch (err) {
            setAlert(`${err?.response?.data.message} 😥`, ERROR, 5000);
            
        }
        setPendingMultiple(false);



    }

    return (
        <>

        <Form onSubmit={onSubmit}>
            <FormGroup>
                <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={e => { setEmail(e.target.value) }}
                />
                
            </FormGroup>
            <SubmitBtnRow>
                {
                    pending
                        ? <Button type='submit' disabled variant="contained" endIcon={<AiOutlineSend />}>
                            <ClipLoader color='#fff' size={20} />
                        </Button>
                        : <Button type='submit' variant="contained" endIcon={<AiOutlineSend />}>
                            Send
                        </Button>
                }
                
            </SubmitBtnRow>
          
            </Form>

            <h2>Activate multiple student Accounts</h2>

        <Form onSubmit={onSubmitMultiple}>
            <FormGroup>
              <TextField
                id="outlined-multiline-static"
                label="Enter Student Emails"
                multiline
                required
                rows={10}
                fullWidth
                onChange={e => {
                  setEmails(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="email_1@gmail.com
email_2@gmail.com
email_3@gmail.com
email_4@gmail.com"
              />
                
            </FormGroup>
            <SubmitBtnRow>
                {
                    pendingMultiple
                        ? <Button type='submit' disabled variant="contained" endIcon={<AiOutlineSend />}>
                            <ClipLoader color='#fff' size={20} />
                        </Button>
                        : <Button type='submit' variant="contained" endIcon={<AiOutlineSend />}>
                            Reactive List
                        </Button>
                }
                
            </SubmitBtnRow>
          
            </Form>
        
        </>
    )
};

export default ReactiveUserForm