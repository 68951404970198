import styled from "styled-components";

export const Container = styled.div`
    width:50% ;
    min-height:50vh ;
    ${'' /* background-color: #eee; */}
    margin:10% auto ;
    padding: 1rem;
    display:flex ;
    flex-direction:column ;
    border: 1px solid #339BFF ;
border-radius:30px ;

`
export const H1 = styled.h1`
        text-align: center;
        font-size: 2rem;
        color: #49483E;
        letter-spacing:8px;
        font-weight: bold;
        position: relative;
        left: 0;
        display: inline;
        color: #49483E;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
        ${'' /* background-image: linear-gradient(
        to right,
        #339BFF5d,
        #339BFF
        );
        transition: all 0.2s;
        -webkit-background-clip: text; */}
    `

export const Form = styled.form`
    width: 100% ;
    display:flex ;
    justify-content:center ;

`;

export const FormGroup = styled.div`
    width:50% ;
    display:flex ;
    flex-direction:column ;
    align-items:flex-start ;
    margin:1rem 0 ;
`