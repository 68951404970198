import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    width:70% ;
    min-height:70vh ;
    ${'' /* overflow:scroll ; */}
    border :1px solid #339BFF;
    margin:5% auto ;
    padding:2rem ;
    display:flex ;
    flex-direction:column ;
    border-radius:30px ;
`;

export const H1 = styled.h1`
        text-align: center;
        font-size: 2rem;
        color: #49483E;
        letter-spacing:8px;
        font-weight: bold;
        position: relative;
        left: 0;
        display: inline;
        color: #49483E;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
        ${'' /* background-image: linear-gradient(
        to right,
        #339BFF5d,
        #339BFF
        );
        transition: all 0.2s;
        -webkit-background-clip: text; */}
    `

    export const Form = styled.form`
    display:flex ;
    ${'' /* background-color:#777 ; */}
    justify-content:space-around ;
    flex-wrap:wrap ;
`

export const FormGroup = styled.div`
    flex:0 1 40% ;
    ${'' /* background-color: #333; */}
    margin:1rem ;
    display:flex ;
    align-items:center ;
    justify-content:space-around ;
    flex-direction:column ;
`
export const SubmitBtnRow = styled.div`
        margin: 2rem 0 ;
        flex:0 1 80% ;
        display:flex ;
         justify-content:space-around ;
        ${'' /* display:flex ; */}
        ${'' /* background-color:#333 ; */}
        ${'' /* align-items:flex-start ; */}

`;


export const SpinnerContainer = styled.div`
    width:5rem ;
    height:5rem;
    margin: 5rem auto ;
    ${'' /* background-color:red ; */}
`

export const HR = styled.hr`
    width:100% ;
    color:red ;
`