import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
    width:70% ;
    height:70vh ;
    ${'' /* overflow:scroll ; */}
    border :1px solid #339BFF;
    margin:13% auto ;
    padding:2rem ;
    display:flex ;
    flex-direction:column ;
    border-radius:30px ;
`;
export const Top = styled.div`
    display:grid;
    
`
export const Header = styled.h2`
    font-size: 30px;
    justify-content: center;
    text-transform: uppercase;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`

export const Form = styled.form`
    display:flex ;
    ${'' /* background-color:#777 ; */}
    justify-content:space-around ;
    flex-wrap:wrap ;
`

export const FormGroup = styled.div`
    flex:0 1 40% ;
    ${'' /* background-color: #333; */}
    margin:1rem ;
    display:flex ;
    align-items:center ;
    justify-content:space-around ;
`
export const SubmitBtnRow = styled.div`
        margin: 2rem 0 ;
        flex:0 1 80% ;
        display:flex ;
         justify-content:space-around ;
        ${'' /* display:flex ; */}
        ${'' /* background-color:#333 ; */}
        ${'' /* align-items:flex-start ; */}

`;
