import React from 'react';
import Cover from '../Components/Cover/Cover'; 
import Facilities from '../Components/Facilities/Facilities';
import Gallery from '../Components/Gallery/Gallery';
import News from '../Components/News/News';
import LoginButton from '../Components/Button/LoginButton';
import Rules from '../Components/Rules/Rules';
import Events from '../Components/Events/Events';
import { useState } from 'react';


const Home = (props) => {

    // console.log(props);
    const[view,setView] = useState(false);
    const resize = ()=>{
        if(window.innerWidth >= 1500){
            setView(true);
        }else{
            setView(false);
        }
    }
    window.addEventListener('resize',resize);
    return (
        <div>
         <Cover Cover1text= {props.Covertext} CoverImage1 = {props.CoverImage}/>
         <LoginButton />
         <Facilities />
         <> 
            {
                view ?  <Gallery /> : null
            }
         </>
         <Rules/>
         {/* <News /> */}
         <Events/>
        </div>
    );
}
 
export default Home;