import React from 'react'
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components'

const LoadingContainer = styled.div`
    width:100% ;
    height:100vh ;
    display:flex ;
    justify-content: center ;
    align-items:center ;
`;

const Loading = () => {
  return (
      <>
          <LoadingContainer>
              <ClipLoader size={100} />
          </LoadingContainer>
    </>
  )
}

export default Loading