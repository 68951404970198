import { RiEdit2Fill, RiFileEditFill, RiTodoLine } from "react-icons/ri";
import { BiBuilding, BiCurrentLocation, BiLocationPlus, BiUser, BiUserPlus } from 'react-icons/bi';
import { FiLogOut } from "react-icons/fi";
import { AiOutlineTeam ,AiOutlineRedo} from "react-icons/ai";

let iconStyles = { fontWeight: "bold", color: "white", fontSize: "1.5em" };

const Roles = [
    {
      Role: "Academic Warden",
      Navs:[
        {
          text:"View Queries",
          icon: <RiTodoLine style={iconStyles}/>,
          link:"/academicwarden/queries"
        },
        { 
          text:"Update Personal Details",
          icon: <RiEdit2Fill style={iconStyles}/>,
          link:"/update-me"
        },
        { 
          text:"View Students",
          icon: <BiUser style={iconStyles}/>,
          link:"/academicwarden/students"
        },
        { 
          text:"Log Out",
          icon: <FiLogOut style={iconStyles}/>,
          link:"/logout"
        },
      ],
    },
    {
      Role: "Sub-Warden",
      Navs:[
        {
          text:"View Queries",
          icon: <RiTodoLine style={iconStyles}/>,
          link:"/subwarden/queries"
        },
        { 
          text:"Update Personal Details",
          icon: <RiEdit2Fill style={iconStyles}/>,
          link:"/update-me"
        },
        { 
          text:"Committe Members",
          icon: <AiOutlineTeam style={iconStyles}/>,
          link:"/subwarden/committe-members"
        },
        { 
          text:"View Students",
          icon: <BiUser style={iconStyles}/>,
          link:"/subwarden/students"
        },
        { 
          text:"Add / Remove Students",
          icon: <BiUserPlus style={iconStyles}/>,
          link:"/subwarden/add-students"
        },
        { 
          text:"Re-Active Students",
          icon: <AiOutlineRedo style={iconStyles}/>,
          link:"/subwarden/re-active-students"
        },
        { 
          text:"Log Out",
          icon: <FiLogOut style={iconStyles}/>,
          link:"/logout"
        },
      ],
    },
    {
      Role: "Deputy Vice Chancellor",
      Navs:[
        {
          text:"View Queries",
          icon: <RiTodoLine style={iconStyles}/>,
          link:"/dvc/queries"
        },
        { 
          text:"Update Personal Details",
          icon: <RiEdit2Fill style={iconStyles}/>,
          link:"/update-me"
        },
        { 
          text:"Log Out",
          icon: <FiLogOut style={iconStyles}/>,
          link:"/logout"
        }
      ],
    },
    {
      Role: "Vice Chancellor",
      Navs:[
        {
          text:"View Queries",
          icon: <RiTodoLine style={iconStyles}/>,
          link:"/vc/queries"
        },
        { 
          text:"Update Personal Details",
          icon: <RiEdit2Fill style={iconStyles}/>,
          link:"/update-me"
        },
        { 
          text:"Log Out",
          icon: <FiLogOut style={iconStyles}/>,
          link:"/logout"
        }
      ],
    },
    {
      Role: "Admin",
      Navs:[
        { 
          text:"Add / Remove User",
          icon: <BiUserPlus style={iconStyles}/>,
          link:"/admin-add-remove-users"
        },
        { 
          text:"View / Release Users",
          icon: <BiUser style={iconStyles}/>,
          link:"/admin-view-users"
        },
        { 
          text:"Add Hostels",
          icon: <BiLocationPlus style={iconStyles}/>,
          link:"/admin-add-hostels"
        },
        { 
          text:"View / Update Hostels",
          icon: <BiBuilding style={iconStyles}/>,
          link:"/admin-hostels"
        },
        {
          text:"Update Personal Data",
          icon: <RiEdit2Fill style={iconStyles}/>,
          link:"/update-me"
        },
        {
          text:"Re-Active Users",
          icon: <AiOutlineRedo style={iconStyles}/>,
          link:"/admin-re-active-user"
        },
        { 
          text:"Log Out",
          icon: <FiLogOut style={iconStyles}/>,
          link:"/logout"
        }
      ],
    },
    {
      Role: "Chief Engineer",
      Navs:[
        {
          text:"View Queries",
          icon: <RiTodoLine style={iconStyles}/>,
          link:"/chief-engineer/queries"
        },
        { 
          text:"Update Personal Details",
          icon: <RiEdit2Fill style={iconStyles}/>,
          link:"/update-me"
        },
        { 
          text:"Log Out",
          icon: <FiLogOut style={iconStyles}/>,
          link:"/logout"
        }
      ],
    },
    {
      Role: "Student",
      Navs:[
        {
          text:"View Queries",
          icon: <RiTodoLine style={iconStyles}/>,
          link:"/student/queries"
        },
        { 
          text:"Create Query",
          icon: <RiFileEditFill style={iconStyles}/>,
          link:"/student/create-query"
        },
        { 
          text:"Update Personal Details",
          icon: <RiEdit2Fill style={iconStyles}/>,
          link:"/student/update-my-profile"
        },
        { 
          text:"Update Password",
          icon: <RiEdit2Fill style={iconStyles}/>,
          link:"/update-me"
        },
        { 
          text:"Log Out",
          icon: <FiLogOut style={iconStyles}/>,
          link:"/logout"
        }
      ],
  },
  {
    Role: "Assistant Registrar",
    Navs:[
      {
        text:"View Queries",
        icon: <RiTodoLine style={iconStyles}/>,
        link:"/assistant_registrar/queries"
      },
      { 
        text:"Update Personal Details",
        icon: <RiEdit2Fill style={iconStyles}/>,
        link:"/update-me"
      },
      { 
        text:"Log Out",
        icon: <FiLogOut style={iconStyles}/>,
        link:"/logout"
      }
    ],
  },
  {
    Role: "Security Officer",
    Navs:[
      {
        text:"View Queries",
        icon: <RiTodoLine style={iconStyles}/>,
        link:"/security_officer/queries"
      },
      { 
        text:"Update Personal Details",
        icon: <RiEdit2Fill style={iconStyles}/>,
        link:"/update-me"
      },
      { 
        text:"Log Out",
        icon: <FiLogOut style={iconStyles}/>,
        link:"/logout"
      }
    ],
  }
  ];
  export default Roles;
  
  export const BackRoutes = [
    {
      nav:'/admin'
    },
    {
      nav:'/subwarden'
    },
    {
      nav:'/academicwarden'
    },
    {
      nav:'/chief-engineer'
    },
    {
      nav:'/dvc'
    },
    {
      nav:'/vc'
    },
    {
      nav:'/student'
    },
    {
      nav:'/assistant_registrar'
    },
    {
      nav:'/sec_officer'
    }
  ]