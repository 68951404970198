
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import { HiOutlineMail,HiOutlinePhone } from "react-icons/hi";

import {
    Des,
    Sec,
    H1,
    SubWardensContainer,
    SbwarednCard,
    ContactImg,
    ContactName,
    ContactHostelName,
    ContactDetailRow,
    ConactIconContainer,
    ContactDetailItem

} from "./SubWardensStyle";



const SubWardens = (props) => {

    

    return (
        <Des>
            <Sec>
                <H1>Sub-Wardens</H1>
            </Sec>
            <Sec>
                <SubWardensContainer >
                    {
                        props.users.map(warden => {
                            return (
                                
                                <SbwarednCard data-aos="zoom-in">
                                <ContactImg src={ warden.img}/>
                                    <ContactName> {warden.name} </ContactName>
                                    <ContactHostelName> {warden.hostel} </ContactHostelName>
                                    <ContactDetailRow>
                                        <ConactIconContainer> <HiOutlinePhone color={ "#339BFF"}/> </ConactIconContainer>
                                        <ContactDetailItem>{ warden.mobile}</ContactDetailItem>
                                    </ContactDetailRow>
                                    {/* <ContactDetailRow>
                                        <ConactIconContainer> <HiOutlineMail color={ "#339BFF"}/> </ConactIconContainer>
                                        <ContactDetailItem>{ warden.email}</ContactDetailItem>
                                    </ContactDetailRow> */}
                                </SbwarednCard>
                               
                            )
                        })
                    }
                </SubWardensContainer>
                    
                
                
                
            </Sec>
        </Des>
    );
}


{/* <Slider data-aos="zoom-in">
                                    <div>
                                        <Img src={img1} alt="" />
                                    </div>
                                    <SubSec>
                                        <Sec1>
                                            <ContactTitle>
                                                <H4>Mrs. M. H. C. Priyadarshani</H4>

                                            </ContactTitle>

                                            <Text><P1>Wellamadama 01 Girls’ Hostel</P1></Text>
                                            <ContactInfo>
                                                <IconContainer><Icon><HiOutlinePhone color={"#339BFF"} /></Icon></IconContainer>
                                                <ContactContainer>+94716721961</ContactContainer>
                                            </ContactInfo>
                                            <ContactInfo>
                                                <IconContainer><Icon><HiOutlineMail color={"#339BFF"} /></Icon></IconContainer>
                                                <ContactContainer>priyadarshani@gmail.com</ContactContainer>
                                            </ContactInfo>



                                        </Sec1>
                                    </SubSec>
                                </Slider> */}

 
export default SubWardens;