import React, { useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import ViewQueries from '../../Components/shared/ViewQueries/ViewQueries'
import {Container,Top,Header,SpinnerContainer,DropDownContainer} from '../../Components/shared/ViewQueries/ViewQueriesElements'
import useFetch from '../../Hooks/useFetch'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useAuth from '../../Hooks/useAuth'
const VCViewQueries = (props) => {

  const [url, setUrl] = useState('/api/v1/queries/myHostelQueries?status=pending');

  // get currently pending,rejected,recieved queries
  const { data: queries, isPending: qPending, isError: qError } = useFetch(url);

  // console.log({queries});

  const { user } = useAuth();

  return (
    <Container>
      <Top><Header>Queries</Header></Top>
      <DropDownContainer>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select Query Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={url}
            label="Select Query Type"
            onChange={e => setUrl(e.target.value)}
          >
            <MenuItem value={'/api/v1/queries/myHostelQueries?status=pending'}>My Pending Queries</MenuItem>
            <MenuItem value={'/api/v1/queries/myHostelQueries?status=rejected'}>Rejected by Me</MenuItem>
            <MenuItem value={'/api/v1/queries/myHostelQueries?status=solved'}>Solved by Me</MenuItem>
            {user.role!=='vc' &&  <MenuItem value={'/api/v1/queries/queriesForwardedByMe'}>Forwarded by Me</MenuItem> }
           
          </Select>
        </FormControl>
      </DropDownContainer>
      {qPending && <SpinnerContainer><ClipLoader size={50} /></SpinnerContainer>}
      {qError && <h3>Something went wrong .... 😥  </h3>}
      {queries && <ViewQueries queries={queries} nav2={props.nav3} />}
      {/* <ViewQueries /> */}
    </Container>
  )
}

export default VCViewQueries