import React from 'react'
import Update from '../Components/shared/update me/UpdateMe'
const UpdateMe = (props) => {
  return (
      <>
          {/* <h1>for admin users to update their personal data and passowrds</h1>
          <h4>2 forms need .</h4>
          <h4>one for personal data and the otherone for password update   </h4> */}
          <Update/>
    </>
  )
}

export default UpdateMe