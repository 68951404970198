import styled from "styled-components";

export const DropDownContainer = styled.div`
 
    
    ${'' /* margin-top: 10%; */}
    @media screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
        font-size: 10px;
    }
`;

