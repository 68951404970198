import React from 'react'
import { Container,H1,SpinnerContainer ,LinkRow} from './AdminViewHostelsTableElements';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";
import BackwordButton from '../../Button/BackButton/BackButton';



const AdminViewHostelsTable = (props) => {

    let rows = []
    
    // console.log({ hostels: props.data.data.hostels });
    const hostels = props?.data?.data?.hostels

    hostels.forEach(hostel => {
        const h = {
            id:hostel?.id,
            name: hostel?.name,
            type: hostel?.type,
            capacity: hostel?.capacity,
            premises: hostel?.premises,
            academicWarden: hostel?.academicWarden?.name || '-',
            subWarden:hostel.subWarden?.name || '-'
        }

        rows.push(h);
    })

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));

    //   name,type,capacity,premises,academicWarden,subWarden
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="right">Type</StyledTableCell>
                            <StyledTableCell align="right">Capacity</StyledTableCell>
                            <StyledTableCell align="right">Premises</StyledTableCell>
                            <StyledTableCell align="right">Academic Warden</StyledTableCell>
                            <StyledTableCell align="right">Sub Warden</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((row) => (
                            <TableRow hover={true} component={Link} to={`/admin-update-hostel/${row.id}`}
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell component="th" scope="row">
                                    {row.name}
                                </StyledTableCell>
                                <StyledTableCell align="right">{row.type}</StyledTableCell>
                                <StyledTableCell align="right">{row.capacity}</StyledTableCell>
                                <StyledTableCell align="right">{row.premises}</StyledTableCell>
                                <StyledTableCell align="right">{row.academicWarden}</StyledTableCell>
                                <StyledTableCell align="right">{row.subWarden}</StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <BackwordButton nav1={props.nav2}/>
        </>
            
        
    )
}

export default AdminViewHostelsTable