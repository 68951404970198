import styled from "styled-components";

export const Container = styled.div`
    width:60% ;
    min-height:70vh ;
    border :1px solid #339BFF;
    margin:5% auto ;
    padding:2rem ;
    display:flex ;
    flex-direction:column ;
    border-radius:30px ;
` 

export const H1 = styled.h1`
        text-align: center;
        font-size: 2rem;
        color: #49483E;
        letter-spacing:8px;
        font-weight: bold;
        position: relative;
        left: 0;
        display: inline;
        color: #49483E;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
        -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
        ${'' /* background-image: linear-gradient(
        to right,
        #339BFF5d,
        #339BFF
        );
        transition: all 0.2s;
        -webkit-background-clip: text; */}
    `
