import React, { useEffect, useState } from 'react'
import { Container,FormGroup,H1,Form,SubmitBtnRow,HR } from './AdminUpdateHostelElements';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import useFetch from '../../../Hooks/useFetch';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { AiOutlineCloseCircle, AiOutlineFileSync } from "react-icons/ai";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import useAlert from './../../../Hooks/useAlert';
import { SUCCESS, ERROR } from './../../alert/Alert_Types'
import ClipLoader from "react-spinners/ClipLoader";
import BackwordButton from '../../Button/BackButton/BackButton';
const AdminUpdateHostelForm = (props) => {

    const { setAlert } = useAlert();
    const { id } = useParams();

    // fetch academic wardens from API
    const { data: academicWardens, isPending: awPending, isError: awError } = useFetch('/api/v1/admin-users?role=academic_warden');

    
    const [subWardens, setSubWardens] = useState([]);
    const [swPending, setSwPending] = useState(true);
    const [swError, setSwError] = useState(false);

    // ============== form states start =====================

    const [name, setName] = useState(props.hostel?.name);
    const [type, setType] = useState(props.hostel?.type);
    const [capacity, setCapacity] = useState(props.hostel?.capacity);
    const [premise, setPremise] = useState(props.hostel?.premises);
    const [subWarden, setSubWarden] = useState(props.hostel.subWarden?.id || null);
    const [academicWarden, setAcademicWarden] = useState(props.hostel.academicWarden?.id || null);

    // ============== form states end =====================

    const [pending, setPending] = useState({
        hostelDataPending: false,
        awUpdatePending: false,
        swUpdatePending: false
    })

    useEffect(() => {
        const fetchAvailableSubWardens = async () => {
            try {
                const res = await axios.get('/api/v1/admin-users?role=sub_warden');
                // console.log();
                setSubWardens(res.data.data.users)
                setSwPending(false);
            } catch (error) {
                // console.log(error.response);
                setSwPending(false);
                setSwError(true);
            }
        }

        fetchAvailableSubWardens();


    }, [subWarden])


    const onDataSubmit = async e => {
        e.preventDefault();

        const formData = { name, type, capacity, premise }
        setPending({ hostelDataPending: true, awUpdatePending: false, swUpdatePending: false });
        try {
            
            const res = await axios.patch(`/api/v1/hostels/hostelData/${id}`, formData);
            // console.log(res);
            if (res.status === 201) {
                setAlert(`${res.data?.message}`, SUCCESS, 5000);
            }
            setPending({ hostelDataPending: false, awUpdatePending: false, swUpdatePending: false });

        } catch (err) {
            // console.log(err.response);
            // console.log(err.response?.data?.message);
            setAlert(`${err.response?.data?.message}`, ERROR, 5000);
            setPending({ hostelDataPending: false, awUpdatePending: false, swUpdatePending: false });

        }
    }

    const onAwSubmit = async (e) => {
        e.preventDefault();

        const formData = { academicWarden };
        setPending({ hostelDataPending: false, awUpdatePending: true, swUpdatePending: false });
        try {
            const res = await axios.patch(`/api/v1/hostels/hostelAcademicWarden/${id}`, formData);
            // console.log({ res });
            if (res.status === 201) {
                setAlert(`${res.data?.message}`, SUCCESS, 5000);
            }
            setPending({ hostelDataPending: false, awUpdatePending: false, swUpdatePending: false });
            
        } catch (err) {
            // console.log(err.response);
            // console.log(err.response?.data?.message);
            setAlert(`${err.response?.data?.message}`, ERROR, 5000);
            setPending({ hostelDataPending: false, awUpdatePending: false, swUpdatePending: false });
        }
    }

    const reset = e => {
        setName('');
        setType('boys');
        setCapacity('');
        setPremise('');
        setSubWarden('');
        setAcademicWarden('')
    }

    const onSwSubmit = async (e) => {
        e.preventDefault();
        setPending({ hostelDataPending: false, awUpdatePending: false, swUpdatePending: true });
        const formData = { subWarden };

        try {
            const res = await axios.patch(`/api/v1/hostels/hostelSubWarden/${id}`, formData);
            // console.log(res);
            setPending({ hostelDataPending: false, awUpdatePending: false, swUpdatePending: false });
            if (res.status === 201) {
                setAlert(`${res.data.message}`, SUCCESS, 5000);
            }

        } catch (error) {
            // console.log(error);
            // console.log(error.response?.data?.message);
            setPending({ hostelDataPending: false, awUpdatePending: false, swUpdatePending: false });
            setAlert(`${error.response?.data?.message}`, ERROR, 5000);
        }

    }

    return (
       
        <>

            {/* ============ hostel details update form =============  */}
            <Form onSubmit={onDataSubmit}>
                <FormGroup>
                    <TextField
                        type='text'
                        id="outlined-basic"
                        label="Hostel Name"
                        variant="outlined"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                        fullWidth
                    />
                </FormGroup>
                <FormGroup>
                    <FormControl sx={{ m: 1, minWidth: 370 }} >
                        <InputLabel id="demo-simple-select-label">Hostel Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            label="Hostel Type"
                            required
                            onChange={e => setType(e.target.value)}
                        >
                            <MenuItem value={'boys'}>Boys</MenuItem>
                            <MenuItem value={'girls'}>Girls</MenuItem>
                            <MenuItem value={'bikku'}>Bhikku</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <TextField
                        type='text'
                        id="outlined-basic"
                        label="Capacity"
                        variant="outlined"
                        value={capacity}
                        onChange={e => setCapacity(e.target.value)}
                        required
                        fullWidth
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        type='text'
                        id="outlined-basic"
                        label="Premise"
                        variant="outlined"
                        value={premise}
                        onChange={e => setPremise(e.target.value)}
                        required
                        fullWidth
                    />
                </FormGroup>
                <SubmitBtnRow>
                    
                    <Button onClick={reset} color='error' variant="contained" endIcon={<AiOutlineCloseCircle />}>
                        Clear
                    </Button>
                    {pending.hostelDataPending
                        ?
                        <Button type='submit' color='success' disabled variant="contained" endIcon={<AiOutlineFileSync />}>
                            <ClipLoader size={20} color='#fff' />
                        </Button>
                        :
                        <Button type='submit' color='success' variant="contained" endIcon={<AiOutlineFileSync />}>
                            Update
                        </Button>
                    }
                    
                </SubmitBtnRow>
            </Form>

            <HR />

            {/* ================ hostel academic warden submit form ============= */}
            <H1>Update academic warden</H1>
            <Form onSubmit={onAwSubmit}>
                <FormGroup>
                    <FormControl sx={{ m: 1, minWidth: 370 }} >
                        <InputLabel id="demo-simple-select-label">Select Academic Warden</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={academicWarden}
                            label="Select Academic Warden"
                            required
                            onChange={e => setAcademicWarden(e.target.value)}
                        >
                            {awPending && <p>still loading data ....</p>}
                            {awError && <p>error while fetching data ...</p>}
                            {academicWardens && academicWardens.data.users?.map((user, id) => {
                                return (
                                    <MenuItem key={id} value={user.id}>{user.name}</MenuItem>
                                    
                                )
                            })}
                            {academicWardens && academicWardens?.data.results === 0 ? <p>None Available</p> : <span></span>}

                        </Select>
                    </FormControl>
                </FormGroup>
                <SubmitBtnRow>
                {pending.awUpdatePending
                        ?
                        <Button type='submit' color='success' disabled variant="contained" endIcon={<AiOutlineFileSync />}>
                            <ClipLoader size={20} color='#fff' />
                        </Button>
                        :
                        <Button type='submit' color='success' variant="contained" endIcon={<AiOutlineFileSync />}>
                            Update Academic Warden
                        </Button>
                    }
                    
                </SubmitBtnRow>
            </Form>

            <HR />
            {/* ================= hostel swub warden submit form =============== */}
            <H1>Update sub warden</H1>
            
            <Form onSubmit={onSwSubmit} >
                <FormGroup>
                    <FormControl sx={{ m: 1, minWidth: 370 }} >
                        <InputLabel id="demo-simple-select-label">Select Sub Warden</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subWarden}
                            label="Select Sub Warden"
                            required
                            onChange={e => setSubWarden(e.target.value)}
                        >
                            {swPending && <p>still loading data ....</p>}
                            {swError && <p>error while fetching data ...</p>}
                            {subWardens && subWardens?.map((user, id) => {
                                return (
                                    <MenuItem key={id} value={user.id}>{user.name}</MenuItem>
                                    
                                )
                            })}
                            {subWardens && subWardens?.length === 0 ? <p>None Available 😥</p> : <span></span>}
                        </Select>
                        {/* <Typography variant="caption" display="block" gutterBottom>
                             Before assign a new sub warden, Please remove the currently assigned sub warden from the hostel
                         </Typography> */}
                    
                    </FormControl>
                    {/* <Button onClick={releaseSubWarden} color='error' variant="contained" endIcon={<AiOutlineCloseCircle />}>
                         Release Currently Assigned Sub Warden
                     </Button> */}
                </FormGroup>
                <SubmitBtnRow>
                    
                    {
                        pending.swUpdatePending
                            ? <ClipLoader />
                            :<Button type='submit' color='success' variant="contained" endIcon={<AiOutlineFileSync />}>
                        Update Sub Warden
                    </Button>
                    }
                </SubmitBtnRow>
            </Form>
            <BackwordButton nav1={props.nav2}/>
        </>
        
    )
}

export default AdminUpdateHostelForm