    import UC from "../Components/UnderConstruction/UC";


const UnderConstruction = () => {
    return ( 
        
        <UC/>
        
     );
}
 
export default UnderConstruction;