import React from 'react'
import { useParams } from 'react-router-dom'
import UpdateStudentForm from '../../Components/2_student/update student data form/UpdateStudentForm'
import useFetch from '../../Hooks/useFetch';
import ClipLoader from "react-spinners/ClipLoader";
import { H1,Container,SpinnerContainer } from '../../Components/2_student/update student data form/UpdateStudentFormElements';


const StudentUpdate = (props) => {

  //fetch cuurent loggin user
  const { data, isPending, isError} = useFetch(`/api/v1/students/myProfile`);
  const user = data?.data.myData[0];
  return (
      <Container>
      <H1>Update Student</H1>
      {isError && <h1>Something went wrong  ... 😥</h1>}
      {isPending && <SpinnerContainer><ClipLoader  size={50} /></SpinnerContainer>}
      {data && <UpdateStudentForm user={user} nav2={props.nav3}/>}
      
    </Container>
  )
}

export default StudentUpdate