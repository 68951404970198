import styled from "styled-components"


export const AlertContainer = styled.div`
    width:100vw ;
    height:3rem ;
    background-color:${props => props.color || '#333'} ;
    backdrop-filter:blur(50px);
    padding:1rem ;
    display:flex ;
    justify-content:center ;
    position: fixed;
    top:0 ;
    left: 0;
    z-index: 1000000000000000;
    overflow:hidden ;

    ${'' /* &:before{
        position:absolute ;
        top:0 ;
        left:0 ;
        content:"" ;
        width:100% ;
        height:100% ;
       
        background:linear-gradient(135deg,rgba(255,255,255,0.1),rgba(255,255,255,0));
    backdrop-filter:blur(10px);
    } */}

`

export const AlertText = styled.p`
    font-size:1rem ;
    color:white ;
    position: absolute;
    top:0 ;
    z-index:1000000000000001 ;
    font-weight:bold ;
`
