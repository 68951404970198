import { Container } from '@mui/system'
import React from 'react'
import ViewStudent from '../../Components/shared/viewStudent/ViewStudent'


const SubWardenViewStudent = (props) => {
  return (
      <>
          {/* <h1>this is # subwarden view student # page </h1>
          <p>full details of a student will display here</p> */}
      <Container>
            <ViewStudent nav2={props.nav3}/>
          </Container>
          
    </>
  )
}

export default SubWardenViewStudent