import React from 'react'
import AdminAddHostelForm from '../../Components/1_admin/add hostel form/AdminAddHostelForm'

const AdminAddHostels = (props) => {
  return (
    <>
      <AdminAddHostelForm nav2={props.nav3}/>
    </>
  )
}

export default AdminAddHostels