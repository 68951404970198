import styled from "styled-components";
export const Container = styled.div`
    width:60%;
    border :1px solid #339BFF;
    margin:15% auto ;
    padding: 0 2rem ;
    display:flex ;
    flex-direction:column ;
    border-radius:30px ;
`
export const AddStudents = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 2%;
`
export const RemoveGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    margin: 2%;
    padding-bottom: 4%;
`
export const Header = styled.div`
    font-size: 15px;
    text-align: left;
    text-transform: uppercase;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    background: linear-gradient(90deg, rgba(51,155,255,1) 0%, rgba(0,48,255,1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
`
export const Buttons = styled.div`
    margin: 2% 5%;
`
export const Row = styled.div`
    display: flex;
    align-items: center;

`
export const Row1 = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const TextBox = styled.div`
    margin: 2% 5%;
    width: 340px;
`
export const AddGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 2%;
    padding-bottom: 2%;
`
export const HR = styled.hr`
    height: 2px;
    background-color: #49483E;
    border: none;
    margin: 2% 0;
`

