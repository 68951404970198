import { useState,useEffect } from "react";
import { HiOutlineMail,HiOutlineEyeOff } from "react-icons/hi";
import * as L from './LoginElements';
import useAuth from "../../Hooks/useAuth";
import useAlert from "../../Hooks/useAlert";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { ERROR, SUCCESS, WARNING } from './../alert/Alert_Types';
const Login = () => {

    const { login, loading ,error,clearErrors,isAuthenticated,user,loadUser} = useAuth();
    const { alerts, setAlert } = useAlert();


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);

    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();

        if (email && password) {
            login({ email, password });
        }

    }

    useEffect(() => {

        // loadUser();

        if (!loading && user && isAuthenticated) {
            switch (user?.role) {
                case 'admin':
                    navigate('/admin')
                    break;
                case 'student':
                    navigate('/student')
                    break;
                case 'sub_warden':
                    navigate('/subwarden')
                    break;
                case 'academic_warden':
                    navigate('/academicwarden')
                    break;
                case 'cheif_engineer':
                    navigate('/chief-engineer')
                    break;
                
                case 'dvc':
                    navigate('/dvc')
                    
                    break;
                case 'vc':
                    navigate('/vc')
                    
                    break;
                case 'assistant_registrar':
                    navigate('/assistant_registrar')
                    
                    break;
                case 'security_officer':
                    navigate('/security_officer')
                    
                    break;
            
                default:
                    navigate('/login')
                    break;
            }
        }
        

        if (error) {
            
            setAlert(error, ERROR,2000);
            clearErrors();
        }
    
    
    }, [error,isAuthenticated,user])
    

    return (
        <L.LoginContainer>
            <L.LoginTitleContainer>

                <L.LoginMainTitle>Login</L.LoginMainTitle>
                <L.LoginSecondaryTitle>Hello! Welcome to the Login.</L.LoginSecondaryTitle>
            </L.LoginTitleContainer>
            <L.Login>
                <L.LoginForm onSubmit={onSubmit}>
                    <L.LoginFormGroup>
                        <L.LoginFormLabel>Email</L.LoginFormLabel>
                        <L.LoginFormInput type="email" placeholder="Enter Email" value={email} onChange={ e=>setEmail(e.target.value)} />
                        <L.FormIcon> <HiOutlineMail color={"#555"}/> </L.FormIcon>
                    </L.LoginFormGroup>
                    <L.LoginFormGroup>
                        <L.LoginFormLabel>Password</L.LoginFormLabel>
                        <L.LoginFormInput type="password" placeholder="Enter Password" value={password} onChange={ e=>setPassword(e.target.value)}/>
                        <L.FormIcon> <HiOutlineEyeOff color={"#555"}/> </L.FormIcon>
                    </L.LoginFormGroup>
                    <L.LoginFormGroup>
                        { loading && <L.FormSubmitBtn disabled >  <ClipLoader size={20} />  </L.FormSubmitBtn>}
                        { !loading && <L.FormSubmitBtn > Login  </L.FormSubmitBtn>}
                        

                        <L.FormPasswordReset to="/forgot-password">Forgot Password ?</L.FormPasswordReset>
                    </L.LoginFormGroup>
                </L.LoginForm>
            </L.Login>
        </L.LoginContainer>
    )
}
 
export default Login;