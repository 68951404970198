import React, { useState } from 'react'
import { Container, Form, FormGroup, H1, SubmitBtnRow } from './AdmiAddUserFormElements'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { AiOutlineUserAdd,AiOutlineUserDelete ,AiOutlineInfoCircle} from "react-icons/ai";
import axios from 'axios';
import useAlert from '../../../Hooks/useAlert';
import { ERROR, SUCCESS } from './../../alert/Alert_Types';
import { ClipLoader } from 'react-spinners';
import BackwordButton from '../../Button/BackButton/BackButton';
import Typography from '@mui/material/Typography';

const AdmiAddUserForm = (props) => {

    const [title, setTitle] = useState('dr');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('admin');
    const [pending, setPending] = useState({
        add: false,
        remove: false
    });

    const { setAlert } = useAlert();
    
    const [remEmail, setRemEmail] = useState();
    

    const onSubmit = async e => {
        e.preventDefault();

        const formData = { title, name, email, role };
        setPending({add:true,remove:false});
        try {
            
            const res = await axios.post('/api/v1/admin-users', formData);
            // console.log({res});
            if (res.status === 201) {
                setPending({add:false,remove:false});
                setAlert(`${res.data.message || 'success'} 👍`, SUCCESS, 5000);
            }

        } catch (error) {
            // console.log(error.ressonse?.data);
            // console.log(error.response.data.message);
            setAlert(`${error?.response.data.message} 😥`, ERROR, 5000);
            setPending({add:false,remove:false});
            
        }

        
    }

    const onRemove = async e => {
        e.preventDefault();

        const email = remEmail;
        setPending({add:false,remove:true});
        try {
            
            const res = await axios.delete(`/api/v1/admin-users/email/${email}`);
            // console.log({res});
            if (res.status === 204) {
                setPending({add:false,remove:false});
                setAlert(`${res.data.message || 'success'} 👍`, SUCCESS, 5000);
            }

        } catch (error) {
            // console.log(error.ressonse?.data);
            // console.log(error.response.data.message);
            setAlert(`${error?.response.data.message} 😥`, ERROR, 5000);
            setPending({add:false,remove:false});
            
        }

    }

    return (
        <Container>
            <H1>add user</H1>
            <Form onSubmit={onSubmit} >
                <FormGroup>
                    <FormControl >
                        <InputLabel id="demo-simple-select-label">Title</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={title}
                            label="Title"
                            required
                            onChange={e => setTitle(e.target.value)}
                        >
                            <MenuItem value={'prof'}>Prof</MenuItem>
                            <MenuItem value={'dr'}>Dr</MenuItem>
                            <MenuItem value={'mr'}>Mr</MenuItem>
                            <MenuItem value={'mrs'}>Mrs</MenuItem>
                            <MenuItem value={'miss'}>Miss</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        type='text'
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        type='email'
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                    />

                </FormGroup>
                <FormGroup>
                    <FormControl sx={{ m: 1, minWidth: 320 }} >
                        <InputLabel id="demo-simple-select-label">Role</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={role}
                            label="Role"
                            required
                            fullWidth
                            onChange={e => setRole(e.target.value)}
                        >
                            <MenuItem value={'admin'}>Admin</MenuItem>
                            <MenuItem value={'vc'}>Vice Chancellor</MenuItem>
                            <MenuItem value={'dvc'}>Deputy Vice Chancellor</MenuItem>
                            <MenuItem value={'assistant_registrar'}>Assistant Registrar</MenuItem>
                            <MenuItem value={'cheif_engineer'}>Chief Engineer</MenuItem>
                            <MenuItem value={'academic_warden'}>Academic Warden</MenuItem>
                            <MenuItem value={'sub_warden'}>Sub Warden</MenuItem>
                            <MenuItem value={'security_officer'}>Security Officer</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    
                </FormGroup>
                <SubmitBtnRow>
                    {pending.add
                        ? <Button type='submit' variant="contained" disabled endIcon={!pending.add && <AiOutlineUserAdd />} >
                            <ClipLoader size={20} color={'#1ba3de'} />
                        </Button>
                        : <Button type='submit' variant="contained" endIcon={!pending.add && <AiOutlineUserAdd />} >
                            Add User
                        </Button>
                        
                    }
                    

                </SubmitBtnRow>
            </Form>
            <hr />
            <H1>remove user</H1>
            <Form onSubmit={onRemove}>
                <FormGroup>
                    <TextField
                        type='email'
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        value={remEmail}
                        onChange={e => setRemEmail(e.target.value)}
                        required
                    />
                </FormGroup>
                <Typography variant="caption" display="block" gutterBottom>
                <AiOutlineInfoCircle color='#ed2618' size={'1rem'} /> Cannot delete VC,DVC,Chief_engineer users from the system. When adding new VC,DVC,Cheif_engineer, exisisting
                    user will deactivate automatically
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                <AiOutlineInfoCircle size={'1rem'}  color='#ed2618' /> Before delete Academic Warden or Sub Warden, those users need to be released from their hostels. Visit 'View Users'
                    to release respective user from their hostels
                </Typography>
                <SubmitBtnRow>
                    {pending.remove
                        ? <Button type='submit' variant="contained" disabled endIcon={!pending.remove && <AiOutlineUserDelete />} >
                            <ClipLoader size={20} color={'#ed2618'} />
                        </Button>
                        : <Button type='submit' variant="contained" color='error' endIcon={!pending.remove && <AiOutlineUserDelete />} >
                            Delete User
                        </Button>
                        
                    }
                    

                </SubmitBtnRow>
            </Form>
            <BackwordButton nav1={props.nav2}/>
        </Container>
    );
}

export default AdmiAddUserForm