import React, { useState } from 'react'
import { Container, Form, FormGroup, H1 } from './ResetPasswordFormElements'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {SUCCESS,ERROR} from './../../Components/alert/Alert_Types'
import ClipLoader from "react-spinners/ClipLoader";
import useAlert from './../../Hooks/useAlert';
const ResetPasswordForm = () => {

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const { token } = useParams();

    const { setAlert } = useAlert();

    const [pending, setPending] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async e => {
        e.preventDefault();
        if (password !== passwordConfirm) {
            //show alert . passwords donot equal
            setAlert('Passwords doesn\'t match', ERROR, 2000);
            setPassword('');
            setPasswordConfirm('');
        }
        setPending(true)
        try {
            const res = await axios.patch(`/api/v1/auth/resetPassword/${token}`,{password,passwordConfirm});
            // console.log(res.data);
            if (res.data.status === 'success') {
                // show success alert
                setAlert(`${res.data.message} 👍`, SUCCESS, 5000);
            }
            setPending(false);
            navigate('/login')
        } catch (err) {
            // console.log(err.response.data);
            // show alert error
            setAlert(`${err?.response.data.message} 🙄🤨`, ERROR, 5000);
            setPending(false)
        }

        
    }

    return (
        <Container>
            <H1>reset password</H1>
            <Form onSubmit={onSubmit}>
                <FormGroup>
                    <TextField
                        type='password'
                        margin='normal'
                        fullWidth
                        id="outlined-basic"
                        label="Enter New Password"
                        variant="outlined"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                    />
                    <TextField
                        type='password'
                        margin='normal'
                        fullWidth
                        id="outlined-basic"
                        label="Confirm New Password"
                        variant="outlined"
                        value={passwordConfirm}
                        onChange={e => setPasswordConfirm(e.target.value)}
                        required
                    />
                    {pending
                        ? <Button type='submit' fullWidth variant="contained"  > <ClipLoader size={20} color='#FFF' />  </Button>
                        :<Button type='submit' fullWidth variant="contained" endIcon={<SendIcon />} >Send</Button>  
                    }

                </FormGroup>
            </Form>
        </Container>
    )
}

export default ResetPasswordForm