import { useState } from 'react';
import * as d from './DropDownElements'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const DropDown = (props) => {

    const [value, setValue] = useState('Wellamadama');
    
    const handleChange = (e) => {
        props.setPremise(e.target.value)
      };
    

    return (
        <d.DropDownContainer>
            <FormControl sx={{ m: 1,minWidth: 250 }}>
                <InputLabel id="demo-simple-select-helper-label">Hostel Premise</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={props.premise}
                    label="Hostel Premise"
                    onChange={handleChange}
                >
                    <MenuItem value={'wellamadama'}>Wellamadama</MenuItem>
                    <MenuItem value={'engineering'}>Faculty of Engineering, Hapugala</MenuItem>
                    <MenuItem value={'medicine'}>Faculty of Medicine, Karapitiya</MenuItem>
                    <MenuItem value={'technology'}>Faculty of Technology</MenuItem>
                    <MenuItem value={'agriculture'}>Faculty of Agriculture, Mapalana</MenuItem>
                    <MenuItem value={'allied health science'}>Faculty of Allied Health Science</MenuItem>
                </Select>
                <FormHelperText>Select Your Hostel Premise</FormHelperText>
            </FormControl>
        </d.DropDownContainer>
    );
}
 
export default DropDown;
