import React, { useState } from "react";
import { Container } from "./ViewStudentsElements";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import BackwordButton from "../../Button/BackButton/BackButton";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import axios from "axios";


//Mudith Perera (7/16/2022)

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);



  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const ViewStudents = (props) => {

  const [feeUpdating, setFeeUpdating] = useState(false);

  let data = React.useMemo(() => [],[]);

  const students = props?.data;

  const updateFee = async (e,value) => {
    
    const formData = {paidStatus:value};
    try {
      const res = await axios.patch('/api/v1/students/hostelfeeupdate', formData);
      setFeeUpdating(true);

      if (res.status === 200) {
        setFeeUpdating(false);
      }
    } catch (error) {
      setFeeUpdating(false);
      
    }

  }
  
  students.forEach(student => {
    const h = {
      studentNo:student.studentNo,
      active:student.active?'Active':'Deactive',
      admissionYear:student.admissionYear,
      // hostelFee:  (
      //   <TextField
      //       id="hostel"
      //     select
      //     size="small"
      //       // label="hostel"
      //       // disabled={false}
      //       value={student.hostelFee?true:false}
      //     onChange={(e,value) => {
      //         // send api request to update value
      //         updateFee(value)
      //       alert('request sent')
      //       }}
      //       style = {{width: 100}}
            
      //       //helperText="Please select your currency"
      //     >
      //         {/* {ahPending && <p> ...  still loading data 🤞 ....</p>} */}
      //         {/* {ahError && <p> ...  error while fetching data 🙄 ....</p>} */}
      //         {/* {hostels && hostels.data.hostels?.map((user, id) => {
      //             return (
      //                 <MenuItem key={id} value={user.id}>{user.name}</MenuItem>
                      
      //             )
      //         })} */}
          
      //     {
      //       false ? (<p> ...  updating data 🤞 ....</p>) : (<>
      //         <MenuItem key={student.hostelFee} value={true}>Paid</MenuItem>
      //         <MenuItem key={!student.hostelFee} value={false}>UnPaid</MenuItem>
      //       </>)
      //     }
              
          
      //     </TextField>
      // ),
      hostelFee:student.hostelFee ? 'Paid':'Unpaid',
      email:student.email,
      committeeMember:student.committeeMember?'Yes':'No',
      hostelName:student.hostel.name,
      faculty:student.faculty,
      firstName:student.firstName,
      lastName:student.lastName,
      initials:student.initials,
      level:student.level,
      roomNo:student.roomNo,
      specialRemarks:student.specialRemarks
    }
    data.push(h);
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Student No",
        accessor: "studentNo", // accessor is the "key" in the data
      },
      {
        Header: "Account Status",
        accessor: "active", // accessor is the "key" in the data
      },
      {
        Header: "Admission Year",
        accessor: "admissionYear",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Hostel Fee",
        accessor: "hostelFee",
      },
      {
        Header: "Committee Member",
        accessor: "committeeMember",
      },
      {
        Header: "Hostel Name",
        accessor: "hostelName",
      },
      {
        Header: "Faculty",
        accessor: "faculty",
      },
      {
        Header: "Firstname",
        accessor: "firstName",
      },
      {
        Header: "Lastname",
        accessor: "lastName",
      },
      {
        Header: "Initials",
        accessor: "initials",
      },
      
      {
        Header: "Level",
        accessor: "level",
      },
      {
        Header: "Room No",
        accessor: "roomNo",
      },
      {
        Header: "Special Remarks",
        accessor: "specialRemarks",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );


  return (
    <Container>
      
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 540 }} {...getTableProps()} style={{ border: "solid 3px black", borderRadius: 10 }}>
          <Table>
            <div>
              <thead>
                {headerGroups.map((headerGroup) => (
              
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{
                          borderBottom: "solid 4px rgb(37, 150, 190)",
                          color: "black",
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "🔽"
                              : "🔼"
                            : ""}
                        </span>
                        <div>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      </th>
                    ))}
                  </TableRow>
                ))}
                <TableRow>
                  <th
                    colSpan={visibleColumns.length}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={state.globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </th>
                </TableRow>
              </thead>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              padding: "8px",
                              border: "solid 1px gray",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </div>
          </Table>
        </TableContainer>
      </Paper>
      <BackwordButton nav1={props.nav2} />
    </Container>
  );
};

export default ViewStudents;
