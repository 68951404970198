export const wellamadamaSW = [
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden1.jpg'),
        name: "Mrs. M.H.C. Priyadarshani",
        hostel: "Wellamadama 01 Girls’ Hostel",
        mobile:"+94718560738",
        email:"priyadarshani@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden2.jpg'),
        name: "Mrs. Shamini Karunarathne",
        hostel: "Wellamadama 02 Girls’ Hostel",
        mobile:"+94716721961",
        email:"shamini@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden3.jpg'),
        name: "Mrs. Chamila Jayalal",
        hostel: "Wellamadama 03 Girls’ Hostel",
        mobile:"+94719364151",
        email:"chamila@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden4.jpg'),
        name: "Ms. A. P. Hemanthi",
        hostel: "Eliyakanda New Girls’ Hostel",
        mobile:"+94714469525",
        email:"hemanthi@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden5.jpg'),
        name: "Mrs. P Kumarasingha",
        hostel: "Eliyakanda Old Girls’ Hostel",
        mobile:"+94702519055",
        email:"shamini@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden6.jpg'),
        name: "Mrs. Nirmala Wijerathna",
        hostel: "Pamburana 02 Girls’ Hostel",
        mobile:"+94714994092",
        email:"nirmala@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden7.jpg'),
        name: "Mr. Srinath Dissanayake",
        hostel: "Eliyakanda New Boys’ Hostel",
        mobile:"+94718153801",
        email:"srinath@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden7.jpg'),
        name: "Mr. Srinath Dissanayake",
        hostel: "Eliyakanda Old Boys’ Hostel",
        mobile:"+94718153801",
        email:"srinath@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden8.png'),
        name: "Mr. Chandana Samarawickrama",
        hostel: "Meddawatta Boys’ Hostel",
        mobile:"+94715549398",
        email:"chandana@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/wellamadama/sub wardens/warden9.jpg'),
        name: "Mr. Kularathna",
        hostel: "Walauwatta Bikkhu Hostel",
        mobile:"+94710396105",
        email:"nirmala@gmail.com"
    }
]

export const engineeringSW = [
    {
        img:require('./../images/subwarden & academic wardens/engineering/sub wardens/j wijerathna.png'),
        name: "Mr. A.M.J.R Wijerathna",
        hostel: " Boy's Hostel A,B,C ",
        mobile:"+94703694338",
        email:"jagath@eng.ruh.ac.lk"
    },
    {
        img:require('./../images/subwarden & academic wardens/engineering/sub wardens/ajantha_danthanarayane.png'),
        name: "Mr. Ajantha Danthnarayane",
        hostel: "Hostel - D",
        mobile:"+94717378995 / 0769476552",
        email:"Ajanthadanthanarayana@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/engineering/sub wardens/nagahawatte.png'),
        name: "Mrs. N.W.N.P.D. Nagahawatte",
        hostel: "Girl's Hostel A & B",
        mobile:"+94702475833",
        email:"priyani@eng.ruh.ac.lk"
    }
]

export const medicineSW = [
    {
        img:require('./../images/subwarden & academic wardens/medicine/subwardens/mahinda_bandara.jpg'),
        name: "Mr. H.M.M.T. Bandara",
        hostel: "Kuwait Boys Hostel",
        mobile:"+94716019887",
        email:"bandarah@eng.ruh.ac.lk"
    },
    {
        img:require('./../images/subwarden & academic wardens/medicine/subwardens/wasantha_manel.png'),
        name: "Miss. G.L Wasantha Manel",
        hostel: "Kuwait Girls ,Stemco House Girl’s Hostel New Girl’s Hostel",
        mobile:"+94718126277",
        email:"Glwmanel@gmail.com"
    }
]


export const technologySW = [
    {
        img:require('./../images/subwarden & academic wardens/technology/sub wardens/kusala_gunewardana.png'),
        name: "Mr. N.B.Kusala Gunawardana",
        hostel: " Boy's Hostel ",
        mobile:"+94714335101",
        email:"kusalagunawardane@gmail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/technology/sub wardens/s.p.a_gunewardana.png'),
        name: "Miss. S.P.A. Gunawardhana",
        hostel: "Girl's Hostel",
        mobile:"+94702132518 / +94740012375",
        email:"subashini@fot.ruh.ac.lk"
    }
]
export const agriSW = [
    {
        img:require('./../images/subwarden & academic wardens/agriculture/sub wardens/jayantha vitharana.png'),
        name: "Mr. Jayantha Vitharana ",
        hostel: " Block 01,02,03,04 & Unit 01,02,03,04 Male Hostel ",
        mobile:"+94711712637",
        email:"jayanthavitharana@gamail.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/agriculture/sub wardens/champika.png'),
        name: "Ms. Dolamulla Kankanamge Champika",
        hostel: "Block 01,02,03,04 , Unit 05,06 Female Hostel, New Hostel Number 02",
        mobile:"+94701833452",
        email:"champikadolamulla@gmail.com"
    }
]


export const allied_health_sciSW = [
    {
        img:require('./../images/subwarden & academic wardens/allied health science/sub wardens/lalith kumara.png'),
        name: "Mr.Lalith Kumara",
        hostel: "Godakanda Boy's Hoste ",
        mobile:"+94763416121",
        email:"lalithkmr@yahoo.com"
    },
    {
        img:require('./../images/subwarden & academic wardens/allied health science/sub wardens/nadeeshani.png'),
        name: "Miss.R.N Nadeeshani",
        hostel: "Labuduwa New Girls’ Hostel, OLD Girls’ Hostel",
        mobile:"+94716255361 / +94763490587",
        email:"Nilangiruwanpura@gmail.com"
    }
]

